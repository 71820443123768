import React from "react";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import { confirmExit, goToAllSegments } from "./SegmentUtils";
import { EVENT_SEGMENT_CREATION_CANCELLED } from 'src/constants/MixpanelEvents.js';

const SegmentCancel = ({ mixpanel, filterId }) => {
  const confirmUnapprove = () => {
    swal({
      title: "Are you sure?",
      className: "segment-cancel-modal",
      text: "By clicking cancel, this segment will \n not be saved.",
      icon: "error",
      buttons: {
        confirm: {
          text: "Yes, Cancel",
          value: "confirm",
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      width: 584,
      height: 369,
    }).then((confirm) => {
      if (confirm === "confirm") {
        window.removeEventListener("beforeunload", confirmExit);
            mixpanel.track(EVENT_SEGMENT_CREATION_CANCELLED, {
              EVENT_SEGMENT_CREATION_CANCELLED: {
                Location: filterId ? "Edit Segment" : "Create Segment",
              },
            })
        goToAllSegments();
      }
    });
  };

  // render
  return (
    <React.Fragment>
      <Button variant="outline-primary" onClick={confirmUnapprove}>
        Cancel
      </Button>
    </React.Fragment>
  );
};

export default SegmentCancel;
