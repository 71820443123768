import React from "react";
import ManageTabComponent from "src/components/reviews/manage_reviews/tabs/ManageTab/ManageTabComponent";
import TrackTabComponent from "src/components/reviews/manage_reviews/tabs/TrackTab/TrackTabComponent";
import PrivateFeedbackTabComponent from "src/components/reviews/manage_reviews/tabs/private-tab/PrivateFeedbackTabComponent";
import GetReviews from "src/components/reviews/get_reviews/GetReviews";
import ReviewResults from "src/components/reviews/results/ReviewResults";
import { MANAGE_REVIEW_TABS } from "src/shared/Constants";
import { PageHeader } from "src/shared/styleguide/common/PageHeader";
import { useLocation, useHistory } from "react-router-dom";
import Button from "src/shared/styleguide/button/Button";
import { showCompanyId } from "src/shared/SessionHelper";
import TabsComponent from "src/shared/tabs/TabsComponent";
import UrlUtil from "src/utils/UrlUtil";
import {
  EVENT_VIEWED_REVIEW_RESULTS,
  EVENT_VIEWED_MANAGE_REVIEWS,
  EVENT_VIEWED_TRACK_INVITATIONS,
  EVENT_VIEWED_PRIVATE_FEEDBACK,
} from "src/constants/MixpanelEvents";
import "./ReviewsComponent.scss";

export const ReviewsComponent = ({ client, tab, mixpanel }) => {
  // state
  const [selectedViewBy, setSelectedViewBy] = React.useState();
  const [manageDateFilter, setManageDateFilter] = React.useState();
  const [feedbackDateFilter, setFeedbackDateFilter] = React.useState();
  const [hasGetReviewsBtn, setHasGetReviewsBtn] = React.useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const filterParam = urlParams.get("filter");

  // variables
  const tabs = [
    {
      eventKey: MANAGE_REVIEW_TABS.results.eventKey,
      title: MANAGE_REVIEW_TABS.results.title,
      pageHeader: MANAGE_REVIEW_TABS.results.pageHeader,
      path: MANAGE_REVIEW_TABS.results.path,
      documentTitle: MANAGE_REVIEW_TABS.results.documentTitle,
      component: <ReviewResults client={client} mixpanel={mixpanel} />,
      mixpanelEvent: EVENT_VIEWED_REVIEW_RESULTS,
    },
    {
      eventKey: MANAGE_REVIEW_TABS.manage.eventKey,
      title: MANAGE_REVIEW_TABS.manage.title,
      pageHeader: MANAGE_REVIEW_TABS.manage.pageHeader,
      path: MANAGE_REVIEW_TABS.manage.path,
      documentTitle: MANAGE_REVIEW_TABS.manage.documentTitle,
      component: (
        <ManageTabComponent
          selectedDateFilter={manageDateFilter}
          selectedViewBy={selectedViewBy}
          client={client}
          mixpanel={mixpanel}
        />
      ),
      mixpanelEvent: EVENT_VIEWED_MANAGE_REVIEWS,
    },
    {
      eventKey: MANAGE_REVIEW_TABS.track.eventKey,
      title: MANAGE_REVIEW_TABS.track.title,
      pageHeader: MANAGE_REVIEW_TABS.track.pageHeader,
      path: MANAGE_REVIEW_TABS.track.path,
      documentTitle: MANAGE_REVIEW_TABS.track.documentTitle,
      component: <TrackTabComponent mixpanel={mixpanel} />,
      mixpanelEvent: EVENT_VIEWED_TRACK_INVITATIONS,
    },
    {
      eventKey: MANAGE_REVIEW_TABS.privateFeedback.eventKey,
      title: MANAGE_REVIEW_TABS.privateFeedback.title,
      pageHeader: MANAGE_REVIEW_TABS.privateFeedback.pageHeader,
      path: MANAGE_REVIEW_TABS.privateFeedback.path,
      documentTitle: MANAGE_REVIEW_TABS.privateFeedback.documentTitle,
      component: (
        <PrivateFeedbackTabComponent
          selectedDateFilter={feedbackDateFilter}
          client={client}
          mixpanel={mixpanel}
        />
      ),
      mixpanelEvent: EVENT_VIEWED_PRIVATE_FEEDBACK,
    },
    {
      eventKey: MANAGE_REVIEW_TABS.getReviews.eventKey,
      title: MANAGE_REVIEW_TABS.getReviews.title,
      pageHeader: MANAGE_REVIEW_TABS.getReviews.pageHeader,
      path: MANAGE_REVIEW_TABS.getReviews.path,
      documentTitle: MANAGE_REVIEW_TABS.getReviews.documentTitle,
      component: <GetReviews mixpanel={mixpanel} />,
    },
  ];

  // methods
  const getReviewsUrl = UrlUtil.joinUrl(
    "/companies",
    showCompanyId,
    "get-reviews",
  );

  // states
  const location = useLocation();
  const [selectedTab, setSelectedTab] = React.useState(tabs[0]);
  const history = useHistory();

  // effects
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("tab") || params.has("Tab")) {
      const tabParam = params.get("tab") || params.get("Tab");
      const currentTab = tabParam.toLowerCase().replace(/ /g, "");
      tabs.forEach((tab, index) => {
        if (tab.title.toLowerCase().replace(/ /g, "") === currentTab) {
          onTabChange(index);
        }
      });
    } else if (tab) {
      tabs.forEach((tabOption, index) => {
        if (tabOption.path === tab) {
          onTabChange(index);
          if (params.has("date")) {
            if (tabOption.eventKey === MANAGE_REVIEW_TABS.manage.eventKey) {
              setManageDateFilter(parseInt(params.get("date"), 10));
            } else if (
              tabOption.eventKey === MANAGE_REVIEW_TABS.privateFeedback.eventKey
            ) {
              setFeedbackDateFilter(parseInt(params.get("date"), 10));
            }
          }
        }
      });
    }

    if (params.has("viewBy")) {
      setSelectedViewBy(
        params.get("viewBy", parseInt(params.get("viewBy"), 10)),
      );
    }

    if (filterParam === "all") {
      onTabChange(1);
    }
  }, []);

  // methods
  const onTabChange = (tabIndex, evt) => {
    const selectedTab = tabs[tabIndex];
    if (selectedTab.mixpanelEvent && evt) {
      mixpanel.track(selectedTab.mixpanelEvent, {
        from: "tab",
      });
    }
    history.push(
      `/companies/${showCompanyId}/${selectedTab.path}${
        filterParam && selectedTab.path === "manage-reviews"
          ? "?filter=" + filterParam
          : ""
      }`,
    );
    document.title = selectedTab.documentTitle;
    setSelectedTab(selectedTab);
    //  hide or show get reviews btn
    //setHasGetReviewsBtn(tabIndex !== 4);
  };

  // render
  return (
    <React.Fragment>
      <PageHeader
        className="reviews-page-header"
        showArrow={false}
        leftComponent={selectedTab.pageHeader}
        rightComponent={
          hasGetReviewsBtn ? (
            <Button href={getReviewsUrl} variant="primary">
              Get Reviews
            </Button>
          ) : (
            <React.Fragment />
          )
        }
      />
      <TabsComponent
        className="reviews"
        unmountOnExit={true}
        onTabChange={onTabChange}
        tabs={tabs}
        selectedTab={selectedTab.eventKey}
      />
    </React.Fragment>
  );
};

export default ReviewsComponent;
