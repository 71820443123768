import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Row, Col, Table } from "react-bootstrap";
import Button from "src/shared/styleguide/button/Button";
import Card from "src/shared/styleguide/card/Card";
import { NO_CACHE } from "src/shared/Constants";
import { EVENT_COMMUNICATION_PREFERENCES_EDIT } from "src/constants/MixpanelEvents";
import { UPDATE_COMMUNICATION_PREFERENCES } from "src/graphql/customers_segments/customers/mutations.js";
import CustomerCommPrefImage from "src/assets/images/customer/user-plus.svg";
import { addToast } from "src/utils/ToastUtil";
import "./CustomerContent.scss";

const CommPreferencesCard = ({
  mixpanel,
  customerData,
  commPreferences,
  setCommPreferences,
}) => {
  // state
  const [dirtyCommPreferences, setDirtyCommPreferences] = useState(
    commPreferences,
  );
  const [updateContact] = useMutation(UPDATE_COMMUNICATION_PREFERENCES);

  // variables
  const commPreferencesAccessors = ["email", "call", "text", "mail"];

  // methods
  const commPreferencesOnClick = (grandparent, parent, child) => {
    if (grandparent) {
      setDirtyCommPreferences({
        ...commPreferences,
        [grandparent]: {
          ...commPreferences[grandparent],
          [parent]: {
            ...dirtyCommPreferences[grandparent][parent],
            [child]: !dirtyCommPreferences[grandparent][parent][child],
          },
        },
      });
    }
  };

  const isYes = (item) => (item ? "Yes" : "No");

  const onSaveClick = async () => {
    mixpanel.track(EVENT_COMMUNICATION_PREFERENCES_EDIT, {
      "Action: Member clicks 'Save Changes'": {
        "Communication Preferences": true,
      },
    });
    try {
      await updateContact({
        variables: {
          contactId: customerData.contactId,
          companyId: customerData.companyId,
          memberWantsSms: dirtyCommPreferences.sources.manual.text,
          memberWantsCall: dirtyCommPreferences.sources.manual.call,
          memberWantsMail: dirtyCommPreferences.sources.manual.mail,
          memberWantsEmail: dirtyCommPreferences.sources.manual.email,
        },
        fetchPolicy: NO_CACHE,
      }).then((res) => {
        if (res && res.data && res.data.updateContact) {
          const { data } = res;
          setCommPreferences({
            ...dirtyCommPreferences,
            setting: {
              email: data ? data.updateContact.wantsEmail : false,
              call: data ? data.updateContact.wantsCalls : false,
              text: data ? data.updateContact.wantsSms : false,
              mail: data ? data.updateContact.wantsMail : false,
            },
          });
          addToast({
            message: "Communication Preferences have been updated.",
            type: "success",
          });
        }
      });
    } catch (e) {
      console.error("COMMUNICATION PREFERENCES ERROR: ", e);
    }
  };

  // effects
  useEffect(() => {
    setDirtyCommPreferences(commPreferences);
  }, [commPreferences]);

  // render
  return (
    <Card>
      <div className="customer-card-icon-container">
        <img src={CustomerCommPrefImage} alt="Communication Preferences" />
      </div>
      <div className="customer-card-content-container">
        <Row className="customer-card-title">
          <Col sm={12}>
            <h2>Communication Preferences</h2>
            <div className="customer-card-subtitle">
              This section contains basic communication preferences
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={8} sm={11}>
            <Row>
              <Col>
                <Table className="customer-communication-preferences-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Email</th>
                      <th>Call</th>
                      <th>SMS</th>
                      <th>Mail</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="comm-pref-setting">
                      <td>Setting</td>
                      {commPreferencesAccessors.map((el, i) => (
                        <td key={`setting-${i}`}>
                          {isYes(commPreferences.setting[el])}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {/* One td produces intentional whitespace  */}
                      <td>Sources</td>
                    </tr>
                    <tr>
                      <td className="preferences-table-indent">
                        Direct Connect
                      </td>
                      {commPreferencesAccessors.map((el, i) => (
                        <td key={`direct-connect-${i}`}>
                          {isYes(commPreferences.sources.directConnect[el])}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="preferences-table-indent">Manual</td>
                      {commPreferencesAccessors.map((el, i) => (
                        <td key={`manual-${i}`}>
                          {
                            <span
                              className="color-link"
                              onClick={() => {
                                commPreferencesOnClick("sources", "manual", el);
                              }}
                            >
                              {isYes(dirtyCommPreferences.sources.manual[el])}
                            </span>
                          }
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="preferences-table-indent">Customer</td>
                      {commPreferencesAccessors.map((el, i) => (
                        <td key={`customer-${i}`}>
                          {isYes(commPreferences.sources.customer[el])}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
                <Button
                  size="lg"
                  variant="primary"
                  disabled={
                    JSON.stringify(commPreferences) ===
                    JSON.stringify(dirtyCommPreferences)
                  }
                  onClick={() => {
                    onSaveClick();
                  }}
                >
                  Save Changes
                </Button>
                <Button
                  size="lg"
                  className="ml-3"
                  variant="outline-secondary"
                  onClick={() => {
                    setDirtyCommPreferences(commPreferences);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

CommPreferencesCard.propTypes = {
  mixpanel: PropTypes.object,
  customerData: PropTypes.object,
  commPreferences: PropTypes.object.isRequired,
  setCommPreferences: PropTypes.func.isRequired,
};

export default CommPreferencesCard;
