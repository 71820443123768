import React from "react";
import Button from "src/shared/styleguide/button/Button";
import Modal from "src/shared/styleguide/modal/Modal";
import Form from "src/shared/styleguide/form/Form";
import GoogleSmartInviteHelp from "./GoogleSmartInviteHelp";
import { showCompanyId } from "src/shared/SessionHelper";
import { QUERY_REVIEW_DESTINATIONS_MASTER } from "src/graphql/settings/reviews/queries";
import {
  MUTATION_CREATE_REVIEW_DESTINATION,
  MUTATION_UPDATE_REVIEW_DESTINATION,
} from "src/graphql/settings/reviews/mutations";
import { useQuery, useMutation } from "@apollo/react-hooks";
import AngieListSmartInviteHelp from "./AngieListSmartInviteHelp";
import FacebookSmartInviteHelp from "./FacebookSmartInviteHelp";
import PopupIcon from "src/shared/styleguide/common/PopupIcon";
import GoogleConnectButton from "./GoogleConnectButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { EVENT_TRACK_INVITE_DESTINATIONS } from "src/constants/MixpanelEvents";
import { addToast } from "src/utils/ToastUtil"

const SmartInviteAddSite = ({
  destinations = [],
  mixpanel,
  fetchingDestinations,
  editDestination,
  fetchDestinations,
  setEditDestination,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [helpModalShown, setHelpModalShown] = React.useState("");
  const formik = useFormik({
    initialValues: {
      url: "",
      source: null,
      gmailOnly: true,
      accessToken: "",
      refreshToken: "",
    },
    validationSchema: Yup.object().shape({
      source: Yup.object().required("Field required"),
      url: Yup.string()
        .url("Please enter a valid URL")
        .required(""),
    }),
  });

  // call apis
  const { data: dataSources } = useQuery(QUERY_REVIEW_DESTINATIONS_MASTER);
  const [createDestination] = useMutation(MUTATION_CREATE_REVIEW_DESTINATION);
  const [updateDestination] = useMutation(MUTATION_UPDATE_REVIEW_DESTINATION);

  // methods
  const setConnected = (value) => {
    if (!value) {
      setTokens({ accessToken: "", refreshToken: "" });
    }
    formik.setFieldValue("connectionStatus", value);
  };
  const setTokens = ({ accessToken, refreshToken }) => {
    formik.setFieldValue("accessToken", accessToken);
    formik.setFieldValue("refreshToken", refreshToken);
  };
  const resetForm = () => {
    formik.resetForm({
      values: {
        url: "",
        source: null,
        gmailOnly: true,
        connectionStatus: false,
        refreshToken: "",
        accessToken: "",
      },
    });
  };
  const saveSettings = () => {
    let request;
    if (formik.values.id) {
      request = updateDestination({
        variables: {
          id: formik.values.id,
          url: formik.values.url,
          gmailOnly: formik.values.gmailOnly,
          connectionStatus: formik.values.connectionStatus
            ? "connected"
            : "not_connected",
          accessToken: formik.values.accessToken,
          refreshToken: formik.values.refreshToken,
        },
      });
    } else {
      request = createDestination({
        variables: {
          companyId: showCompanyId,
          url: formik.values.url,
          defaultReviewDestinationId: formik.values.source.value,
          gmailOnly: formik.values.gmailOnly,
          connectionStatus: formik.values.connectionStatus
            ? "connected"
            : "not_connected",
          accessToken: formik.values.accessToken,
          refreshToken: formik.values.refreshToken,
        },
      });
    }
    request
      .then(() => {
        if (formik.values.id) {
          setEditDestination();
        }
        fetchDestinations({ updateLoader: false, fetchPolicy: "network-only" });
        addToast({
          type: "success",
          message: "Invitation settings updated successfully.",
        });
        mixpanel.track(EVENT_TRACK_INVITE_DESTINATIONS, {
          "Add Site": formik.values.source
            ? formik.values.source.label
            : "No Source",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Invitation settings not updated.",
        });
      });
  };

  // effects
  React.useEffect(() => {
    if (editDestination) {
      let source;
      if (
        dataSources &&
        dataSources.defaultDestinationsList &&
        dataSources.defaultDestinationsList.defaultDestinations &&
        dataSources.defaultDestinationsList.defaultDestinations.length > 0
      ) {
        let srcMatch = dataSources.defaultDestinationsList.defaultDestinations.find(
          (src) => src.displayName === editDestination.displayName,
        );
        if (srcMatch) {
          source = { value: srcMatch.id, label: srcMatch.displayName };
        }
      }
      formik.resetForm({
        values: {
          url: editDestination.url,
          id: editDestination.id,
          gmailOnly: editDestination.gmailOnly,
          connectionStatus: editDestination.connectionStatus,
          accessToken: editDestination.accessToken,
          refreshToken: editDestination.refreshToken,
          source,
        },
      });
      setShowModal(true);
    }
  }, [editDestination]);

  // render
  return (
    <React.Fragment>
      <Button
        disabled={fetchingDestinations || !formik.isValid}
        onClick={() => {
          resetForm();
          setEditDestination();
          setShowModal(true);
        }}
      >
        + Add Site
      </Button>
      <Modal
        modalSize="md"
        onHide={() => setShowModal(false)}
        header={
          editDestination ? "Edit Review Site Details" : "Add New Review Site"
        }
        body={
          <div
            className="p-2"
            style={{
              minHeight: 240,
            }}
          >
            <Form.Group controlId="source">
              <Form.Label className="text-dark">Source</Form.Label>
              <Form.Select
                disabled={editDestination ? true : false}
                value={formik.values.source}
                onChange={(val) => formik.setFieldValue("source", val)}
                placeholder="Select source"
                options={
                  dataSources &&
                  dataSources.defaultDestinationsList &&
                  dataSources.defaultDestinationsList.defaultDestinations &&
                  dataSources.defaultDestinationsList.defaultDestinations
                    .length > 0
                    ? dataSources.defaultDestinationsList.defaultDestinations
                        .filter(
                          (src) =>
                            !destinations
                              .map((existingSrc) => existingSrc.displayName)
                              .includes(src.displayName),
                        )
                        .map((src) => ({
                          value: src.id,
                          label: src.displayName,
                        }))
                    : []
                }
                menuBuffer={30}
                searchable={true}
                clearable={false}
              />
            </Form.Group>

            {/* Show Google Form */}
            {formik.values.source && formik.values.source.label === "Google" ? (
              <React.Fragment>
                <div className="mt-2 mb-3">
                  <GoogleConnectButton
                    connected={formik.values.connectionStatus}
                    setConnected={setConnected}
                    setTokens={setTokens}
                  />
                </div>

                <div className="text-dark mb-2">
                  Send Google Review Invitation to:
                </div>

                <Form.Group
                  className="d-inline-flex mr-4"
                  controlId="onlyGmailAddresses"
                >
                  <Form.Check
                    custom
                    checked={formik.values.gmailOnly}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "gmailOnly",
                        !formik.values.gmailOnly,
                      );
                    }}
                    type="radio"
                    label="Only Gmail Addresses"
                    className="text-dark"
                  />
                </Form.Group>

                <Form.Group
                  className="d-inline-flex"
                  controlId="allEmailAddresses"
                >
                  <Form.Check
                    custom
                    checked={!formik.values.gmailOnly}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "gmailOnly",
                        !formik.values.gmailOnly,
                      );
                    }}
                    type="radio"
                    label="All Email Addresses"
                    className="text-dark d-inline-flex"
                  />
                </Form.Group>
              </React.Fragment>
            ) : null}

            <Form.Group controlId="url">
              <Form.Label className="text-dark">
                Review Site URL
                <sup>
                  <span className="font-weight-bold text-danger">*</span>
                </sup>
              </Form.Label>
              <Form.Control
                value={formik.values.url}
                placeholder="Enter review site url"
                onChange={formik.handleChange}
              />
            </Form.Group>
            <div className="text-danger">
              {formik.errors.url ? <div>{formik.errors.url}</div> : null}
            </div>

            <div>
              {/* Show Google help */}
              {formik.values.source &&
              formik.values.source.label === "Google" ? (
                <div className="font-italic">
                  <PopupIcon
                    props={{
                      className: "mb-1",
                    }}
                    iconSize={15}
                  />
                  <span className="ml-2">
                    <a
                      href="#"
                      className="sg-text-info"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setHelpModalShown("google");
                      }}
                    >
                      Click here
                    </a>{" "}
                    to see, how to find your Google address.
                  </span>
                </div>
              ) : null}

              {/* Show Facebook help */}
              {formik.values.source &&
              formik.values.source.label === "Facebook" ? (
                <React.Fragment>
                  <PopupIcon
                    props={{
                      className: "mb-1",
                    }}
                    iconSize={15}
                  />
                  <a
                    href="#"
                    className="sg-text-info ml-1"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setHelpModalShown("facebook");
                    }}
                  >
                    Click here
                  </a>{" "}
                  to see, how to find your Facebook address.
                </React.Fragment>
              ) : null}

              {/* Show Angies List help */}
              {formik.values.source &&
              formik.values.source.label === "Angies List" ? (
                <React.Fragment>
                  <PopupIcon
                    props={{
                      className: "mb-1",
                    }}
                    iconSize={15}
                  />
                  <a
                    href="#"
                    className="sg-text-info ml-1"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setHelpModalShown("angielist");
                    }}
                  >
                    Click here
                  </a>{" "}
                  to see, how to find your Angie's List company id.
                </React.Fragment>
              ) : null}
            </div>
          </div>
        }
        footer={
          <React.Fragment>
            <Button
              variant="outline-secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={
                !formik.dirty || formik.values.url === "" || !formik.isValid
              }
              variant="primary"
              onClick={() => {
                saveSettings();
                setShowModal(false);
              }}
            >
              {editDestination ? "Save Changes" : "Add Site"}
            </Button>
          </React.Fragment>
        }
        show={showModal}
        handleClose={() => {}}
      />
      <GoogleSmartInviteHelp
        showModal={helpModalShown === "google"}
        setShowModal={() => {
          setHelpModalShown("");
        }}
      />
      <AngieListSmartInviteHelp
        showModal={helpModalShown === "angielist"}
        setShowModal={() => {
          setHelpModalShown("");
        }}
      />
      <FacebookSmartInviteHelp
        showModal={helpModalShown === "facebook"}
        setShowModal={() => {
          setHelpModalShown("");
        }}
      />
    </React.Fragment>
  );
};

SmartInviteAddSite.propTypes = {
  destinations: PropTypes.array,
  mixpanel: PropTypes.object.isRequired,
  editDestination: PropTypes.object,
  fetchDestinations: PropTypes.func.isRequired,
  setEditDestination: PropTypes.func.isRequired,
};

export default SmartInviteAddSite;
