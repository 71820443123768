import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { PageHeader } from "src/shared/styleguide/common/PageHeader";
import "../CustomerSegmentsContainer.scss";
import CreateNewButton from "../CreateNewButton";
import { SegmentsProvider } from "./SegmentsContext";
import SegmentsSummarySidebar from "./SegmentsSummarySidebar";
import SegmentsOverview from "./SegmentsOverview";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import {
  SHOW_FILTER,
  LIST_CUSTOM_FILTERS,
} from "src/graphql/customers_segments/customers/queries.js";
import { NO_CACHE } from "src/shared/Constants";
import { showCompanyId } from "src/shared/SessionHelper";

// lazy load
const UploadContact = React.lazy(() =>
  import("src/components/upload_contact/UploadContact"),
);

export const SegmentsSummaryContainer = () => {
  // states
  const [showAddCustomersModal, setShowAddCustomersModal] = React.useState(
    false,
  );
  const [data, setData] = useState({});
  const [advanceFilter, setAdvanceFilter] = useState([{}]);

  // variables
  const client = useApolloClient();

  const { filterId } = useParams();
  const history = useHistory();
  const prevPage =
    history.location.state &&
    history.location.state.prevPage &&
    history.location.state.prevPage.includes("segments")
      ? history.location.state.prevPage
      : "";
  const headerRef = React.useRef({ prevPage: prevPage });
  const usePrevPage =
    headerRef && headerRef.current && !!headerRef.current.prevPage;

  /**
   * Gets the segment details using graphql
   */
  const getSegmentData = () => {
    client
      .query({
        query: SHOW_FILTER,
        fetchPolicy: NO_CACHE,
        variables: { id: filterId },
      })
      .then((res) => {
        if (res && res.data.showFilter) {
          setData(res.data.showFilter);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Gets the custom filters using graphql
   */
  const getAdvanceFilters = (props = {}) => {
    client
      .query({
        query: LIST_CUSTOM_FILTERS,
        fetchPolicy: NO_CACHE,
        variables: { companyId: parseInt(showCompanyId) },
      })
      .then((res) => {
        if (res && res.data && res.data.listCustomFilters) {
          setAdvanceFilter(res.data.listCustomFilters);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSegmentData();
    getAdvanceFilters();
  }, []);

  // render
  return (
    <React.Fragment>
      <UploadContact
        // TODO add mixpanel
        mixpanel={() => {}}
        show={showAddCustomersModal}
        setShow={setShowAddCustomersModal}
      />
      <PageHeader
        className="customers-header"
        showArrow={usePrevPage}
        onArrowClick={() => {
          history.push(usePrevPage ? headerRef.current.prevPage : "#");
        }}
        leftComponent={<span>{data.name}</span>}
        rightComponent={
          <CreateNewButton
            setShowAddCustomersModal={setShowAddCustomersModal}
          />
        }
      />
      <div>
        <SegmentsProvider>
          <div className="segment-details-container">
            <div className="segment-details-block">
              <Row>
                <Col lg={3} md={4} sm={6} className="segment-summary-sidebar">
                  <SegmentsSummarySidebar
                    data={data}
                    advanceFilter={advanceFilter}
                  />
                </Col>
                <Col lg={9} md={8} sm={6} className="segment-summary-content">
                  <SegmentsOverview data={data} />
                </Col>
              </Row>
            </div>
          </div>
        </SegmentsProvider>
      </div>
    </React.Fragment>
  );
};

SegmentsSummaryContainer.propTypes = {
  mixpanel: PropTypes.object,
};

export default SegmentsSummaryContainer;
