import React, { useState } from "react";
import Form from "src/shared/styleguide/form/Form";
import {
  QUERY_SYNDICATION_SETTINGS,
  MUTATION_UPDATE_SETTINGS,
} from "src/graphql/settings/syndication/queries";
import { useQuery, useMutation } from "@apollo/react-hooks";
import CompanyPlacesTable from "./CompanyPlacesTable";
import { showCompanyId, featureFlags } from "src/shared/SessionHelper";
import "./SyndicationSettings.scss";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";
import SettingsCard from "src/components/settings/reviews/SettingsCard.jsx";
import { EVENT_TRACK_SYNDICATION_STATUS } from "src/constants/MixpanelEvents";
import { addToast } from "src/utils/ToastUtil"

const SyndicationSettings = ({ mixpanel }) => {
  // state
  const [enabled, setEnabled] = useState(false);
  // call apis
  const { loading: loadingSettings, data: dataCompanyInformation } = useQuery(
    QUERY_SYNDICATION_SETTINGS,
    {
      variables: {
        companyId: showCompanyId,
      },
      fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
      onCompleted(data) {
        if (data && data.getCompanyInformation) {
          setEnabled(data.getCompanyInformation.setting.syndicateReviews);
        }
      },
    },
  );
  const [updateSetting] = useMutation(MUTATION_UPDATE_SETTINGS);

  // methods
  const setSyndicationSetting = (syndicatationEnabled) => {
    updateSetting({
      variables: {
        companyId: showCompanyId,
        enabled: syndicatationEnabled,
      },
    })
      .then(() => {
        addToast({
          type: "success",
          message: "Syndication settings updated successfully.",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Syndication settings not updated.",
        });
      });
  };

  // feature flag check
  if (!featureFlags.reviewWrite) {
    return null;
  }

  // render
  return (
    <SettingsCard
      loading={loadingSettings}
      settingsName="Syndication"
      settingsDescription="Automatically publish your reviews to other review sites across the web. To enable syndication, click Status below to ON and verify that your location data is an exact match. If your location is not an exact match, it is unlikely that your reviews will syndicate to other review sites."
      settingsIcons={
        <div
          className="d-inline-flex justify-content-center align-items-center mr-3"
          style={{
            border: "1px solid #7e7e7e",
            width: 28,
            height: 28,
            borderRadius: 4,
            padding: 4,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27.994"
            height="24.771"
            viewBox="0 0 27.994 24.771"
            style={{
              width: 24,
              height: 24,
            }}
          >
            <g
              fill="#7e7e73"
              stroke="#7e7e7e"
              transform="rotate(50 279.314 8.459)"
            >
              <g transform="translate(102.395 204.81)">
                <g>
                  <path
                    d="M110.114 257.246l-2.7 2.7v-13.558a.628.628 0 0 0-1.256 0v13.553l-2.7-2.7a.628.628 0 0 0-.888.888l3.768 3.766a.6.6 0 0 0 .2.137.608.608 0 0 0 .478 0 .616.616 0 0 0 .206-.137l3.778-3.761a.628.628 0 0 0-.888-.888z"
                    transform="translate(-102.395 -243.249)"
                  />
                  <path
                    d="M274.841 209.648a.628.628 0 0 0 0-.888l-3.766-3.766a.616.616 0 0 0-.206-.137.631.631 0 0 0-.478 0 .6.6 0 0 0-.2.137l-3.768 3.766a.628.628 0 1 0 .888.888l2.7-2.7v13.554a.628.628 0 1 0 1.256 0v-13.549l2.7 2.7a.626.626 0 0 0 .888 0z"
                    transform="translate(-256.188 -204.81)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      }
      settingsForm={
        <div>
          {/* status */}
          <div className="d-flex flex-row my-4 align-items-center">
            <div className="text-dark" style={{ marginRight: 48 }}>
              Status
            </div>
            <Form.Group className="mb-0" controlId="formBasicsSwitch1">
              <Form.Check
                custom
                checked={enabled}
                onChange={() => {
                  mixpanel.track(EVENT_TRACK_SYNDICATION_STATUS, {
                    Action: "Click",
                    Status: !enabled ? "On" : "Off",
                  });
                  setEnabled(!enabled);
                  setSyndicationSetting(!enabled);
                }}
                type="switch"
                label={<React.Fragment />}
                className="text-dark custom-switch-lg"
              />
            </Form.Group>
          </div>

          {/* table */}
          {enabled ? (
            <CompanyPlacesTable
              oldPlace={
                dataCompanyInformation ? dataCompanyInformation.company : null
              }
            />
          ) : null}
        </div>
      }
    />
  );
};

export default SyndicationSettings;
