import React, { useState } from "react";
import Form from "src/shared/styleguide/form/Form";
import Button from "src/shared/styleguide/button/Button";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import PopupIcon from "src/shared/styleguide/common/PopupIcon";
import * as Yup from "yup";
import { useFormik } from "formik";
import { QUERY_CUSTOM_EMAIL_TEMPLATE } from "src/graphql/settings/reviews/queries";
import { MUTATION_CUSTOM_EMAIL_TEMPLATE } from "src/graphql/settings/reviews/mutations";
import {
  featureFlags,
  showCompanyId,
  showUserId,
} from "src/shared/SessionHelper";
import SettingsCard from "../SettingsCard";
import { Col, Row } from "react-bootstrap";
import CustomEmailTemplatePreview from "./CustomEmailTemplatePreview";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";
import swal from "sweetalert";
import { EVENT_REVIEW_SETTINGS } from "src/constants/MixpanelEvents";
import { addToast } from "src/utils/ToastUtil";

const CustomEmailTemplateSettings = ({ mixpanel }) => {
  // variables
  const labelWidth = 180;
  const linkToUploadLogo = `/companies/${showCompanyId}/settings`;

  // state
  const [dirty, setDirty] = React.useState(false);
  const [userFirstName, setUserFirstName] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [abbreviatedName, setAbbreviatedName] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const formik = useFormik({
    initialValues: {
      id: null,
      status: false,
      autoResend: false,
      unhappyCustomerFeedback: false,
      subject: "",
      fromEmail: "",
      replyEmail: "",
      replyName: "",
      mainBody: "",
      signature: "",
    },
    validationSchema: Yup.object().shape({
      status: Yup.bool(),
      subject: Yup.string()
        .typeError("Invalid type")
        .required("Field required"),
      replyName: Yup.string()
        .typeError("Invalid value")
        .required("Field required"),
      replyEmail: Yup.string()
        .email("Invalid email")
        .typeError("Invalid value")
        .required("Field required"),
      mainBody: Yup.string()
        .typeError("Invalid value")
        .required("Field required"),
      signature: Yup.string()
        .typeError("Invalid value")
        .required("Field required"),
    }),
    onSubmit: (values) => {
      saveChanges({ ...values });
    },
  });

  // variables
  const Logo = ({ size = "5rem" }) => {
    // render
    return logoUrl ? (
      <img
        style={{
          width: size,
          height: size,
          objectFit: "contain",
        }}
        src={logoUrl}
        alt="Company Logo"
      />
    ) : (
      <span
        style={{
          width: size,
          height: size,
        }}
        className="d-inline-flex align-items-center text-center"
      >
        Company Logo
      </span>
    );
  };
  // call apis
  const { loading, data } = useQuery(QUERY_CUSTOM_EMAIL_TEMPLATE, {
    fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
    variables: {
      companyId: showCompanyId,
      companyUserId: showUserId,
    },
    onCompleted: (data) => {
      populateData(data);
    },
  });
  const [saveSettings] = useMutation(MUTATION_CUSTOM_EMAIL_TEMPLATE);

  // methods
  const populateData = (data) => {
    if (data && data.company) {
      setLogoUrl(data.company.logoUrl);
      setAbbreviatedName(data.company.abbreviatedName);
    }
    if (data && data.getCompanyUser) {
      setUserFirstName(data.getCompanyUser.firstName);
    }
    // populate form
    let formValues = {};
    if (
      data &&
      data.getCompanyInformation &&
      data.getCompanyInformation.setting
    ) {
      formValues.unhappyCustomerFeedback =
        data.getCompanyInformation.setting.unhappyCustomerFeedback;
    }
    if (data && (data.customEmailTemplate || data.customEmailTemplateMeta)) {
      const template = data.customEmailTemplate || data.customEmailTemplateMeta;
      if (Object.keys(template).length > 0) {
        formValues.id = template.id;
        formValues.status = template.status === "active";
        formValues.autoResend = template.autoResend;
        formValues.greeting = template.greeting;
        formValues.subject = template.subject;
        formValues.fromEmail = template.fromEmail;
        formValues.replyName = template.replyName;
        formValues.replyEmail = template.replyEmail;
        formValues.mainBody = template.mainBody;
        formValues.signature = template.signature;
        formik.resetForm({
          values: formValues,
        });
      }
    }
  };
  const updateCustomEmailStatus = (status) => {
    swal({
      title: "Are you sure?",
      className: "custom-email-template",
      text:
        "Disabling this feature will stop it from working. Your settings will be retained if you enable this feature again.",
      icon: "error",
      buttons: {
        save: {
          text: "Yes, Disable",
          value: "disable",
        },
        cancel: true,
      },
      dangerMode: true,
      confirmButtonColor: "LightSeaGreen",
      cancelButtonColor: "Crimson",
    }).then((confirm) => {
      if (confirm === "disable") {
        mixpanel.track(EVENT_REVIEW_SETTINGS, {
          "Action: Click Invite settings": "Custom Email Template",
          Status: !formik.values.status ? "On" : "Off",
        });
        formik.setFieldValue("status", !formik.values.status);
        if (
          data &&
          (data.customEmailTemplate || data.customEmailTemplateMeta)
        ) {
          const template =
            data.customEmailTemplate || data.customEmailTemplateMeta;
          let tempStatus = template.status === "active";
          if (tempStatus !== status) {
            saveDisableStatus({
              id: formik.values.id,
              status,
            });
          }
        }
      }
    });
  };
  const saveChanges = ({
    id,
    status,
    autoResend,
    unhappyCustomerFeedback,
    subject,
    replyName,
    replyEmail,
    fromEmail,
    greeting,
    mainBody,
    signature,
  }) => {
    saveSettings({
      variables: {
        companyId: showCompanyId,
        id,
        status: status ? "active" : "inactive",
        autoResend,
        unhappyCustomerFeedback,
        subject,
        greeting,
        replyName,
        replyEmail,
        fromEmail,
        mainBody,
        signature,
      },
    })
      .then(() => {
        addToast({
          type: "success",
          message: "Custom email invitation is updated successfully.",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Custom email invitation is not updated.",
        });
      });
  };

  const saveDisableStatus = ({ id, status }) => {
    saveSettings({
      variables: {
        companyId: showCompanyId,
        id,
        status: status ? "active" : "inactive",
      },
    })
      .then(() => {
        addToast({
          type: "success",
          message: "Custom email invitation is updated successfully.",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Custom email invitation is not updated.",
        });
      });
  };

  // checks
  if (loading || !featureFlags.reviewWrite) {
    return null;
  }

  // render
  return (
    <SettingsCard
      loading={loading}
      settingsName="Custom Email Template"
      settingsDescription="Customize the email invitation we send to your customers to request a review."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34.91"
          height="36.65"
          viewBox="0 0 34.91 36.65"
        >
          <g transform="translate(-9.418 .4)">
            <g transform="translate(9.668)">
              <g>
                <path
                  d="M43.8 12.829l-4.421-3.693V3.9a.828.828 0 0 0-.855-.8h-6.441L29.5.943a4.109 4.109 0 0 0-5.248.008L21.669 3.1h-6.523a.828.828 0 0 0-.855.8v5.318l-4.343 3.611a.813.813 0 0 0-.28.633h.016v18.417A4.106 4.106 0 0 0 13.773 36h26.2a4.106 4.106 0 0 0 4.088-4.121V13.463h.016a.747.747 0 0 0-.277-.634zm-4.425-1.555l2.595 2.156-2.591 1.925zM25.3 2.21a2.47 2.47 0 0 1 3.15.008l1.05.882h-5.256zm-9.364 2.534h21.8V16.58l-9.369 6.975a2.505 2.505 0 0 1-2.969 0l-9.459-7.041zm-1.645 6.605v3.94l-2.509-1.867zm28.131 20.522a2.463 2.463 0 0 1-2.443 2.484H13.773a2.461 2.461 0 0 1-2.443-2.476V15.1l13.1 9.78a4.121 4.121 0 0 0 4.9 0l13.1-9.78z"
                  transform="translate(-9.668)"
                />
              </g>
            </g>
            <g transform="translate(20.954 11.324)">
              <g>
                <path
                  d="M152.137 137.663H147.7a.823.823 0 0 0 0 1.645h4.442a.823.823 0 1 0 0-1.645z"
                  transform="translate(-146.873 -137.663)"
                />
              </g>
            </g>
            <g transform="translate(20.954 15.437)">
              <g>
                <path
                  d="M157.9 187.663h-10.2a.823.823 0 0 0 0 1.645h10.2a.823.823 0 0 0 0-1.645z"
                  transform="translate(-146.873 -187.663)"
                />
              </g>
            </g>
          </g>
        </svg>
      }
      settingsForm={
        <form
          onChange={(evt) => {
            if (evt) {
              setDirty(true);
            }
          }}
          onSubmit={formik.handleSubmit}
        >
          {/* Preview Modal */}
          <CustomEmailTemplatePreview
            companyId={showCompanyId}
            companyAbbreviatedName={abbreviatedName}
            template={formik.values}
            logo={<Logo size="7.5rem" />}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            firstName={userFirstName}
          />

          {/* 1. Status */}
          <div
            key={data ? data.id : "0"}
            className="d-flex flex-row mt-4 align-items-center"
          >
            <div className="d-flex flex-row align-items-center">
              <span className="text-dark mr-2 mt-1">Status</span>
            </div>
            <Form.Group
              style={{ marginLeft: 48 }}
              className="mb-0"
              controlId="customEmailStatus"
            >
              <Form.Check
                custom
                checked={formik.values.status}
                onChange={() => {
                  if (formik.values.status === true) {
                    updateCustomEmailStatus(!formik.values.status);
                  } else {
                    mixpanel.track(EVENT_REVIEW_SETTINGS, {
                      "Action: Click Invite settings": "Custom Email Template",
                      Status: !formik.values.status ? "On" : "Off",
                    });

                    formik.setFieldValue("status", !formik.values.status);
                  }
                }}
                type="switch"
                label={<React.Fragment />}
                className="text-dark custom-switch-lg"
              />
            </Form.Group>
          </div>

          {/* Custom Email Form */}
          {formik.values.status === true ? (
            <div className="mt-4">
              <div className="h3 text-dark mb-0">Email Content</div>
              <div className="p-4 mt-4 custom-email-template-form">
                {/* 1. Auto Resend */}
                <div className="d-flex mb-3 flex-row align-items-center">
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2">Auto Resend</span>
                    <PopupInfo
                      placement="top"
                      message="Enable Auto Resend to resend the invitation if we have not received a review after 3 days."
                      iconSize={16}
                      className="mt-1"
                    />
                  </div>
                  <Form.Group
                    style={{ marginLeft: 12, marginRight: 12 }}
                    className="mb-0"
                    controlId="autoResend"
                  >
                    <Form.Check
                      custom
                      checked={formik.values.autoResend}
                      onChange={() => {
                        formik.setFieldValue(
                          "autoResend",
                          !formik.values.autoResend,
                        );
                      }}
                      type="switch"
                      label={<React.Fragment />}
                      className="text-dark custom-switch-lg"
                    />
                  </Form.Group>
                </div>

                {/* 2. Unsatisfied feedback */}
                <div className="d-flex mb-3 flex-row align-items-center">
                  <div
                    className="d-flex flex-row align-items-start"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2">
                      <span className="mr-1">Only Feedback From</span>
                      <PopupInfo
                        placement="top"
                        message="Enabling this check will redirect the unsatisfied customers to a feedback page and satisfied customers to a review page."
                        iconSize={16}
                        iconClassName="mb-1"
                      />
                      <br />
                      Unsatisfied Customers
                    </span>
                  </div>
                  <Form.Group
                    style={{ marginLeft: 12, marginRight: 12 }}
                    className="mb-0"
                    controlId="unhappyCustomerFeedback"
                  >
                    <Form.Check
                      custom
                      checked={formik.values.unhappyCustomerFeedback}
                      onChange={() => {
                        formik.setFieldValue(
                          "unhappyCustomerFeedback",
                          !formik.values.unhappyCustomerFeedback,
                        );
                      }}
                      type="switch"
                      label={<React.Fragment />}
                      className="text-dark custom-switch-lg"
                    />
                  </Form.Group>
                </div>

                {/* 3. Logo */}
                <div className="d-flex mb-3 flex-row align-items-start">
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2">Logo</span>
                  </div>
                  <Form.Group
                    style={{ marginLeft: 12, marginRight: 12 }}
                    className="mb-0 flex-grow-1 "
                    controlId="logo"
                  >
                    <div className="align-items-end d-flex">
                      <span
                        className="p-2 d-inline-block mr-2"
                        style={{
                          borderRadius: 4,
                          border: "1px solid rgba(126, 126, 126, 0.2)",
                        }}
                      >
                        <Logo />
                      </span>
                      <span className="font-italic m-0">
                        <PopupIcon
                          props={{
                            className: "mb-1",
                          }}
                          iconSize={16}
                        />
                        <a
                          className="ml-1"
                          style={{
                            color: "#428bca",
                          }}
                          href={linkToUploadLogo}
                        >
                          Click here
                        </a>{" "}
                        to update the company logo in Company Information.
                      </span>
                    </div>
                  </Form.Group>
                </div>

                {/* 4. Email Subject */}
                <div className="d-flex mb-3 flex-row align-items-center">
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2">Email Subject</span>
                  </div>
                  <Form.Group
                    style={{ marginLeft: 12, marginRight: 12 }}
                    className="mb-0 flex-grow-1"
                    controlId="subject"
                  >
                    <Form.Control
                      type="text"
                      value={formik.values.subject}
                      name="subject"
                      onChange={formik.handleChange}
                      className="text-dark"
                    />
                  </Form.Group>
                </div>
                {formik.errors.subject ? (
                  <div className="d-flex mb-3 flex-row align-items-center text-danger">
                    <div style={{ width: labelWidth }} />
                    <div className="mx-2 px-1">{formik.errors.subject}</div>
                  </div>
                ) : null}

                {/* 5. From */}
                <div className="d-flex mb-3 flex-row align-items-center">
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2">From</span>
                  </div>
                  <div className="flex-grow">
                    <Row className="flex-grow-1 mr-2">
                      <Col sm={6}>
                        <Form.Group
                          style={{ marginLeft: 12, marginRight: 12 }}
                          className="mb-0 w-100"
                          controlId="replyName"
                        >
                          <Form.Control
                            type="text"
                            value={formik.values.replyName}
                            name="replyName"
                            onChange={(evt) => {
                              const name = evt.target.value.replace(
                                /[^a-z0-9\s]/gi,
                                "",
                              );
                              const nameWithHyphens = name.replace(/\s/g, "-");
                              const maxLength = 25;
                              let abbr = abbreviatedName.substr(0, maxLength);
                              if (abbr.length === maxLength) {
                                abbr = abbr.substr(0, abbr.lastIndexOf("-"));
                              }
                              formik.setValues({
                                ...formik.values,
                                replyName: name,
                                fromEmail: `${nameWithHyphens}-${abbr}@customerlobby.com`,
                              });
                            }}
                            className="text-dark"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6} className="pl-0">
                        <Form.Group
                          style={{ marginLeft: 12, marginRight: 12 }}
                          className="mb-0 w-100"
                          controlId="replyEmail"
                        >
                          <Form.Control
                            type="text"
                            value={formik.values.replyEmail}
                            name="replyEmail"
                            onChange={formik.handleChange}
                            className="text-dark"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="flex-grow-1 mr-2">
                      <Col sm={6}>
                        {formik.errors.replyName ? (
                          <div className="d-flex mt-3 flex-row align-items-center text-danger">
                            <div className="mx-2 px-1">
                              {formik.errors.replyName}
                            </div>
                          </div>
                        ) : null}
                      </Col>
                      <Col sm={6} className="pl-0">
                        {formik.errors.replyEmail ? (
                          <div className="d-flex mt-3 flex-row align-items-center text-danger">
                            <div className="mx-2 px-1">
                              {formik.errors.replyEmail}
                            </div>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* 6. Sent from */}
                <div className="d-flex mb-3 flex-row align-items-center">
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2">Sent from</span>
                  </div>
                  <Form.Group
                    style={{ marginLeft: 12, marginRight: 12 }}
                    className="mb-0 flex-grow-1"
                    controlId="fromEmail"
                  >
                    <Form.Control
                      disabled
                      type="text"
                      value={formik.values.fromEmail}
                      name="fromEmail"
                      onChange={formik.handleChange}
                      className="text-dark"
                    />
                  </Form.Group>
                </div>

                {/* 7. Reply to */}
                <div className="d-flex mb-3 flex-row align-items-center">
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2">Reply to</span>
                  </div>
                  <Form.Group
                    style={{ marginLeft: 12, marginRight: 12 }}
                    className="mb-0 flex-grow-1"
                    controlId="replyTo"
                  >
                    <Form.Control
                      disabled
                      type="text"
                      value={`${formik.values.replyName} <${formik.values.replyEmail}>`}
                      name="replyTo"
                      className="text-dark"
                    />
                  </Form.Group>
                </div>

                {/* 8. Email body */}
                <div className="d-flex mb-3 flex-row align-items-start">
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2">Email Body</span>
                  </div>
                  <Form.Group
                    style={{ marginLeft: 12, marginRight: 12 }}
                    className="mb-0 flex-grow-1"
                    controlId="mainBody"
                  >
                    <Form.Control
                      as="textarea"
                      rows="5"
                      value={`${formik.values.greeting} {auto-populated customer's first name},
${formik.values.mainBody}`}
                      name="mainBody"
                      onChange={(evt) => {
                        const lines = evt.target.value.split("\n");
                        if (lines.length > 1) {
                          formik.setFieldValue(
                            "mainBody",
                            lines.splice(1).join("\n"),
                          );
                        }
                      }}
                      className="text-dark"
                    />
                  </Form.Group>
                </div>
                {formik.errors.mainBody ? (
                  <div className="d-flex mb-3 flex-row align-items-center text-danger">
                    <div style={{ width: labelWidth }} />
                    <div className="mx-2 px-1">{formik.errors.mainBody}</div>
                  </div>
                ) : null}

                {/* 9. Signature */}
                <div className="d-flex mb-4 flex-row align-items-start">
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2">Signature</span>
                  </div>
                  <Form.Group
                    style={{ marginLeft: 12, marginRight: 12 }}
                    className="mb-0 flex-grow-1"
                    controlId="signature"
                  >
                    <Form.Control
                      as="textarea"
                      rows="5"
                      value={formik.values.signature}
                      name="signature"
                      onChange={formik.handleChange}
                      className="text-dark"
                    />
                  </Form.Group>
                </div>
                {formik.errors.signature ? (
                  <div className="d-flex mb-3 flex-row align-items-center text-danger">
                    <div style={{ width: labelWidth }} />
                    <div className="mx-2 px-1">{formik.errors.signature}</div>
                  </div>
                ) : null}

                {/* 10. Save changes */}
                <div className="d-flex mb-4 flex-row align-items-center">
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ minWidth: labelWidth, width: labelWidth }}
                  >
                    <span className="text-dark mt-1 mr-2" />
                  </div>
                  <div className="mx-2 px-1">
                    <Button
                      type="submit"
                      variant="primary"
                      className="mr-2"
                      disabled={!dirty || !formik.isValid}
                    >
                      Save Changes
                    </Button>
                    <Button
                      variant="outline-secondary text-dark"
                      className="mr-2"
                      onClick={() => {
                        setShowPreview(true);
                      }}
                    >
                      Preview
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </form>
      }
    />
  );
};

export default CustomEmailTemplateSettings;
