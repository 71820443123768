import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { pulsem } from "src/shared/SessionHelper";
import TabsComponent from "src/shared/tabs/TabsComponent";
import { APPS_INTEGRATION_SETTINGS_TAB, APPS_INTEGRATION_SETTINGS_ROUTES, } from "src/shared/Constants";
import MobileAppInfo from "./MobileAppInfo";
import AppIntegrations from "./AppIntegrations";
import "./MobileAppInfoScss.scss";

const AppsAndIntegrationSettings = ({
  reload = false,
  client,
  selectedTab: selectedTabProp,
  mixpanel,
  history
}) => {
  // variables
  let tabs = [];
  if (!pulsem) {
    tabs.push({
      eventKey: APPS_INTEGRATION_SETTINGS_TAB.apps,
      title: "Apps",
      component: < MobileAppInfo />,
    })
  }
  tabs.push({
    eventKey: APPS_INTEGRATION_SETTINGS_TAB.integrations,
    title: "Integrations",
    component: < AppIntegrations mixpanel={
      mixpanel
    }
    />
  })

  //  state
  const [selectedTab, setSelectedTab] = useState(
    tabs.find((tab) => tab.eventKey === selectedTabProp) || tabs[0],
  );

  // methods
  const handleRoute = (tabIndex) => {
    const tab = tabs[tabIndex] || tabs[0];
    const {
      eventKey
    } = tab;
    switch (eventKey) {
      case APPS_INTEGRATION_SETTINGS_TAB.apps:
        history.push(APPS_INTEGRATION_SETTINGS_ROUTES.apps);
        break;
      case APPS_INTEGRATION_SETTINGS_TAB.integrations:
        history.push(APPS_INTEGRATION_SETTINGS_ROUTES.integrations);
        break;
      default:
        break;
    }
    setSelectedTab(tab);
  };

  // effects
  useEffect(() => {
    const tab = tabs.find((tab) => tab.eventKey === selectedTabProp) || tabs[0];
    setSelectedTab(tab);
  }, [reload]);

  // render
  return (<
    TabsComponent selectedTab={
      selectedTab.eventKey
    }
    onTabChange={
      handleRoute
    }
    className="tabs tab-apps-settings"
    tabs={
      tabs
    }
  />
  );
};

export default withRouter(AppsAndIntegrationSettings);