import React from "react";
import CustomerDetailsHeader from "./CustomerDetailsHeader";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import CustomersSegments from "./CustomersSegments";
import CustomerDetails from "./CustomerDetails";
import CustomerTransactions from "./CustomerTransactions";
import RecentCampaigns from "./RecentCampaigns";
import CustomerReviews from "./CustomerReviews";
import * as sessionHelper from "src/shared/SessionHelper";
import { featureFlags } from "src/shared/SessionHelper";
import "./CustomerDetailsSidebar.scss";
import { pulsem } from "../SessionHelper";

const CustomerDetailsSidebar = ({
  mixpanel,
  setDetailsShown,
  contact,
  navigateToOldMembers,
}) => {
  // render
  return (
    <div className="customer-details-sidebar-container">
      <CustomerDetailsHeader
        leftComponent="Customer Details"
        rightComponent={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.001"
            height="16"
            viewBox="0 0 16.001 16"
            className="cursor-pointer"
            onClick={() => {
              sessionHelper.setDetailsShown(false);
              setDetailsShown(false);
            }}
          >
            <g>
              <path
                d="M9.415 8l6.292-6.292A1 1 0 0 0 14.293.294L8 6.586 1.708.294A1 1 0 0 0 .293 1.709L6.586 8 .293 14.293a1 1 0 1 0 1.415 1.415L8 9.416l6.292 6.292a1 1 0 0 0 1.415-1.415z"
                transform="translate(0 -.001)"
              />
            </g>
          </svg>
        }
      />
      <div className="customer-details-content">
        {/* 
          style param feels like inline style, and should be considered for 
          removal.  But we do use it per standard when utilizing this library 
          throughout the app. 
        */}
        {/* 
          TODO Scrollbars component accepts an autoHeightMin param that may 
          allow us to remove style param 
        */}
        {/*
          autoHeight?: boolean | undefined;
          autoHeightMin?: number | string | undefined; 
        */}
        <Scrollbars style={{ minHeight: "calc(100vh - 220px)" }}>
          <CustomerDetails
            contact={contact}
            navigateToOldMembers={navigateToOldMembers}
          />
          <CustomerTransactions contactId={contact.contactId} />
          <CustomersSegments
            mixpanel={mixpanel}
            contactId={contact.contactId}
            contact={contact}
          />
          {featureFlags.reviewRead && !pulsem ? (
            <CustomerReviews contact={contact} contactId={contact.contactId} />
          ) : null}
          {featureFlags.campaignRead ? (
            <RecentCampaigns contactId={contact.contactId} />
          ) : null}
        </Scrollbars>
      </div>
    </div>
  );
};

CustomerDetailsSidebar.propTypes = {
  contact: PropTypes.object.isRequired,
  mixpanel: PropTypes.object.isRequired,
};

export default CustomerDetailsSidebar;
