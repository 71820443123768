import React from "react";
import BlockUi from "react-block-ui";
import CalendarUtil from "src/utils/CalendarUtil";
import classnames from "classnames";
import UpArrowIcon from "src/assets/images/reviews/UpArrow.svg";
import DownArrowIcon from "src/assets/images/reviews/DownArrow.svg";
import { MiniCard } from "src/shared/styleguide/card/MiniCard";
import { MINI_CARD_CONTENT_HEIGHT } from "src/constants/ReviewResults";
import PropTypes from "prop-types";
import { EVENT_REVIEW_RESULTS_INTERACTIONS } from "src/constants/MixpanelEvents";

const ResponseRate = ({ loading, data, companyId, filter, mixpanel }) => {
  // variables
  const responseRateStr = (data ? data.currentResponseRate : 0).toString();
  const responseRateLength = responseRateStr.length;

  // render
  return (
    <MiniCard
      loading={loading}
      popupInfoMsg="This shows a percentage of the number of review invitations sent compared to the number of reviews submitted for the current period vs. the previous period. It also shows the growth percentage (negative/positive) for the current period vs the previous period."
      title="RESPONSE RATES"
      viewMore={{
        to: {
          pathname: `/companies/${companyId}/manage-reviews`,
          search: `date=${filter.id}&viewBy=0`,
        },
        label: "View more",
        onClickViewMore: () => {
          if (mixpanel) {
            mixpanel.track(EVENT_REVIEW_RESULTS_INTERACTIONS, {
              Action: "Click",
              Destination: "All reviews",
            });
          }
        },
      }}
    >
      <BlockUi blocking={loading}>
        <div
          style={{
            height: MINI_CARD_CONTENT_HEIGHT,
          }}
          className="pl-3"
        >
          <div className="d-flex align-items-center">
            <span
              className={classnames("text-dark font-weight-bold my-0 mr-2", {
                h1: responseRateLength <= 3,
                h2: responseRateLength >= 3,
              })}
            >
              {responseRateStr}%
            </span>
            <div
              className={classnames("d-inline-block my-0 h5", {
                "response-rate-positive": data && data.change > 0,
                "response-rate-negative": data && data.change < 0,
                "response-rate-neutral": data && data.change === 0,
              })}
            >
              <div className="d-flex align-items-center">
                <span className="font-weight-bold mr-1">
                  {data
                    ? data.change === 0
                      ? 0
                      : Math.abs(data.change).toFixed(1)
                    : 0}
                  %
                </span>
                <img
                  style={{
                    height: "0.8rem",
                  }}
                  src={
                    data && data.change > 0
                      ? UpArrowIcon
                      : data && data.change < 0
                      ? DownArrowIcon
                      : null
                  }
                  alt="Arrow"
                />
              </div>
              {data ? (
                <div>
                  {CalendarUtil.toDateStrByChartType(
                    data.previousRange.from,
                    filter.id,
                    "MMM dd, yyyy",
                    "responseRate",
                    filter.bucket,
                  )}{" "}
                  -{" "}
                  {CalendarUtil.toDateStrByChartType(
                    data.previousRange.to,
                    filter.id,
                    "MMM dd, yyyy",
                    "responseRate",
                    filter.bucket,
                  )}
                </div>
              ) : null}
            </div>
          </div>
          {data ? (
            <div className="h4 my-0 mt-2">
              {CalendarUtil.toDateStrByChartType(
                data.currentRange.from,
                filter.id,
                "MMM dd, yyyy",
                "responseRate",
                filter.bucket,
              )}{" "}
              -{" "}
              {CalendarUtil.toDateStrByChartType(
                data.currentRange.to,
                filter.id,
                "MMM dd, yyyy",
                "responseRate",
                filter.bucket,
              )}
            </div>
          ) : null}
        </div>
      </BlockUi>
    </MiniCard>
  );
};

ResponseRate.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object,
};

export default ResponseRate;
