import React, { useState } from "react";
import Form from "src/shared/styleguide/form/Form";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { QUERY_FEATURE_FLAGS } from "src/graphql/settings/reviews/queries";
import { MUTATION_SMART_QUEUE_CALLING } from "src/graphql/settings/reviews/mutations";
import {
  featureFlags,
  showCompanyId,
  showUserId,
  userType,
  getCompanyFeatureFlags,
} from "src/shared/SessionHelper";
import SettingsCard from "../SettingsCard";
import { REVIEW_SETTINGS_FETCH_POLICY } from "src/constants/ReviewSettings";
import { EVENT_REVIEW_SETTINGS } from "src/constants/MixpanelEvents";
import { addToast } from "src/utils/ToastUtil";
import { COMPANY_USER, ffConstants } from "src/shared/Constants";

const SmartCallQueuingSettings = ({ mixpanel }) => {
  // state
  const [smartCallQueuing, setSmartCallQueuing] = useState(false);

  // call apis
  const { loading } = useQuery(QUERY_FEATURE_FLAGS, {
    fetchPolicy: REVIEW_SETTINGS_FETCH_POLICY,
    variables: {
      companyId: showCompanyId,
    },
    onCompleted(data) {
      const companyFeatureFlags = data.companyFeatureFlags;
      const smartCallQueuinglWrite = getCompanyFeatureFlags(
        ffConstants.smartCallQueuing,
        ffConstants.write,
        companyFeatureFlags,
      );
      setSmartCallQueuing(smartCallQueuinglWrite);
    },
    onError(e) {
      console.error(e);
    },
  });

  const [saveSettings] = useMutation(MUTATION_SMART_QUEUE_CALLING);

  // methods
  const saveChanges = (enabled) => {
    saveSettings({
      variables: {
        companyId: showCompanyId,
        component: "SmartCallQueuing",
        status: enabled,
        userId: showUserId,
        userType: COMPANY_USER,
      },
    })
      .then(() => {
        addToast({
          type: "success",
          message: "Smart Call Queuing settings updated.",
        });
      })
      .catch(() => {
        addToast({
          type: "error",
          message: "Smart Call Queuing settings not updated.",
        });
      });
  };

  React.useEffect(() => {
    setSmartCallQueuing(featureFlags.smartCallQueuinglWrite);
  }, []);

  // render
  return (
    <SettingsCard
      loading={loading}
      settingsName="Smart Call Queuing"
      settingsDescription="Smart Call Queuing enables us to use your customer call credits when you need them the most. If you already have lots of reviews in a given month, we will automatically roll over your customer call credits to the next month ensuring you have a steady flow of new reviews."
      settingsIcons={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36.3"
          height="36.3"
          viewBox="0 0 36.3 36.3"
        >
          <g transform="translate(.5 .5)">
            <path d="M18 0a18 18 0 1 0 18 18A18 18 0 0 0 18 0zm0 34.8A16.8 16.8 0 1 1 34.8 18 16.8 16.8 0 0 1 18 34.8zm0 0" />
            <path
              d="M136.888 131.194l-5.093-.9a.6.6 0 0 0-.636.313l-1.235 2.366a9.612 9.612 0 0 1-4.9-4.9l2.364-1.235a.6.6 0 0 0 .313-.636l-.9-5.092a.6.6 0 0 0-.255-.393A4.2 4.2 0 0 0 120 124.2a13.815 13.815 0 0 0 13.8 13.8 4.2 4.2 0 0 0 3.48-6.551.6.6 0 0 0-.392-.255zM133.8 136.8a12.615 12.615 0 0 1-12.6-12.6 3 3 0 0 1 4.471-2.615l.776 4.4-2.46 1.284a.6.6 0 0 0-.284.745 10.815 10.815 0 0 0 6.288 6.291.6.6 0 0 0 .745-.284l1.285-2.46 4.4.776a3 3 0 0 1-2.621 4.463zm0 0"
              transform="translate(-111 -110.999)"
            />
          </g>
        </svg>
      }
      settingsForm={
        <form>
          {/* 1. Status */}
          <div className="d-flex flex-row mt-4 align-items-center">
            <div className="d-flex flex-row align-items-center">
              <span className="text-dark mr-2  mt-1">Status</span>
            </div>
            <Form.Group
              style={{ marginLeft: 48 }}
              className="mb-0"
              controlId="smartCallQueuingStatus"
            >
              <Form.Check
                custom
                checked={smartCallQueuing}
                onChange={() => {
                  mixpanel.track(EVENT_REVIEW_SETTINGS, {
                    "Action: Click Invite settings": "Smart Call Queuing",
                    Status: !smartCallQueuing ? "On" : "Off",
                  });
                  setSmartCallQueuing(!smartCallQueuing);
                  saveChanges(!smartCallQueuing);
                }}
                type="switch"
                label={<React.Fragment />}
                className="text-dark custom-switch-lg"
              />
            </Form.Group>
          </div>
        </form>
      }
    />
  );
};

export default SmartCallQueuingSettings;
