import React from "react";
import EmailSignatureLink from "./EmailSignatureLink";
import AutoInvitationSettings from "./AutoInvitationSettings";
import SmartCallQueuingSettings from "./SmartCallQueuingSettings";
import CustomEmailTemplateSettings from "./CustomEmailTemplateSettings";
import SmartInviteSettings from "./SmartInviteSettings";

const InvitesSettings = ({ mixpanel }) => {
  // render
  return (
    <React.Fragment>
    <div className="mb-4">
      <SmartInviteSettings mixpanel={mixpanel}/>
    </div>
    <div className="mb-4">
      <CustomEmailTemplateSettings mixpanel={mixpanel}/>
    </div>
      <div className="mb-4">
        <EmailSignatureLink />
      </div>
      <div className="mb-4">
        <AutoInvitationSettings />
      </div>
      <div className="mb-4">
        <SmartCallQueuingSettings mixpanel={mixpanel}/>
      </div>
    </React.Fragment>
  );
};

export default InvitesSettings;
