import React, { useState, useEffect, useContext } from "react";
import SegmentsCard from "./SegmentsCard";
import PaginatorComponent from "src/shared/paginator/PaginatorComponent";
import { NO_CACHE } from "src/shared/Constants";
import {
  LIST_FILTERS,
  LIST_CUSTOM_FILTERS,
} from "src/graphql/customers_segments/customers/queries.js";
import { useApolloClient } from "@apollo/react-hooks";
import { showCompanyId } from "src/shared/SessionHelper";
import { SegmentsContext } from "./SegmentsContext";
import BlockUi from "react-block-ui";
import ResultsNotFound from "src/shared/errorpages/ResultsNotFound";
import StringUtil from "src/utils/StringUtil";
import { SEGMENT_METADATA } from "src/graphql/customers_segments/customers/queries.js";

const SegmentsContent = ({mixpanel}) => {
  const {
    searchTerm,
    filterBy,
    sortBy,
    resetFilters,
    filterCount,
    setFilterCount,
  } = useContext(SegmentsContext);
  const client = useApolloClient();

  // state
  const [expand, setExpand] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [segmentsData, setSegmentsData] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState([{}]);
  // methods
  /**
   * To handle page change event
   * @param {number} selected -- Selected page number
   */
  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
    getSegments({
      page: selected,
    });
  };

  /**
   * Gets the all segment using graphql
   */
  const getSegments = (props = {}) => {
    setLoading(true);
    const queryVariables = {
      companyId: showCompanyId,
      rpp: 10,
      searchTerm: searchTerm ? searchTerm : undefined,
      sortBy: sortBy ? sortBy.value : undefined,
      sortOrder: "desc",
      page: (props.page !== undefined ? props.page : currentPage) + 1,
      filterBy: filterBy ? filterBy.value : undefined,
    };
    client
      .query({
        query: LIST_FILTERS,
        fetchPolicy: NO_CACHE,
        variables: queryVariables,
      })
      .then((res) => {
        if (res && res.data && res.data.listFilters.data) {
          setSegmentsData(res.data.listFilters.data);
          setTotalCustomers(res.data.listFilters.total);
          setPageCount(res.data.listFilters.total / 10);
          getMetaData();
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Gets the custom filters using graphql
   */
  const getAdvanceFilters = (props = {}) => {
    client
      .query({
        query: LIST_CUSTOM_FILTERS,
        fetchPolicy: NO_CACHE,
        variables: { companyId: parseInt(showCompanyId) },
      })
      .then((res) => {
        if (res && res.data && res.data.listCustomFilters) {
          setAdvanceFilter(res.data.listCustomFilters);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *
   * @returns the currently using filters
   */
  const showFilters = () => {
    if (searchTerm !== "") {
      return "All Segments having name " + searchTerm;
    } else if (filterBy.value) {
      return StringUtil.capitalize(
        filterBy.value === "archived" ? "archive" : filterBy.value,
      );
    } else {
      return "All";
    }
  };

  /**
   * Returns the list of activity for the given filter id
   */
  const getMetaData = (props = {}) => {
    client
      .query({
        query: SEGMENT_METADATA,
        fetchPolicy: NO_CACHE,
        variables: { companyId: showCompanyId },
      })
      .then((res) => {
        if (res && res.data && res.data.filterMetaData) {
          setFilterCount({
            ...filterCount,
            all: res.data.filterMetaData.all,
            archived: res.data.filterMetaData.archived,
            custom: res.data.filterMetaData.custom,
            default: res.data.filterMetaData.default,
            favorite: res.data.filterMetaData.favorite,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Calls getsegment whenever segment is updated such as archived, favorite, clone
   */
  const segmentUpdate = () => {
    getSegments({
      page: 0,
      sortBy: sortBy,
      filterBy: filterBy.value,
    });
  };

  // effects
  useEffect(() => {
    if (searchTerm.length >= 3) {
      setFiltersApplied(true);
      getSegments({
        page: 0,
        searchTerm: searchTerm,
      });
    } else if (searchTerm.length === 0) {
      setFiltersApplied(false);
      getSegments({
        page: 0,
      });
    }
  }, [searchTerm]);

  useEffect(() => {
    if (filterBy.value === "all") {
      setFiltersApplied(false);
    } else {
      setFiltersApplied(true);
    }
    getSegments({
      page: 0,
      sortBy: sortBy,
      filterBy: filterBy.value,
    });
  }, [filterBy, sortBy]);

  useEffect(() => {
    getAdvanceFilters();
    getMetaData();
  }, []);

  return (
    <div>
      <div className="segments-content-view mt-4 mb-4">
        <div className="segmentlist-label">Overview</div>
        {
          <div className="mb-2 filters-list">
            Showing results for - {showFilters()}
            {filtersApplied && (
              <a
                target="_blank"
                className="nav-link cursor-pointer reset-filter"
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset Filter
              </a>
            )}
          </div>
        }
      </div>
      <BlockUi loader={<React.Fragment />} blocking={loading}>
        {segmentsData.length === 0 ? (
          <ResultsNotFound
            primaryMsg="No results found"
            secondaryMsg="We couldn’t find any results. Try adjusting your filters to display results."
            primaryMsgClassName="primary-message-style"
            secondaryMsgClassName="secondary-message-style"
          />
        ) : (
          <div>
            {segmentsData.map((item) => (
              <SegmentsCard
                cardData={item}
                totalCustomers={totalCustomers}
                cardKey={item.id}
                key={item.id}
                expand={expand}
                segmentUpdate={segmentUpdate}
                metaData={item.metaData}
                advanceFilter={advanceFilter}
                mixpanel={mixpanel}
              />
            ))}
            <div className="pagination-container">
              {pageCount > 1 ? (
                <PaginatorComponent
                  pageCount={pageCount}
                  pageChangeHandler={pageChangeHandler}
                  currentPage={currentPage}
                />
              ) : null}
            </div>
          </div>
        )}
      </BlockUi>
    </div>
  );
};

export default SegmentsContent;
