import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { SegmentsProvider } from "./SegmentsContext";
import "./Segments.scss";
import SegmentsSidebar from "./SegmentsSidebar";
import SegmentsContent from "./SegmentsContent";

const Segments = ({ mixpanel }) => {
  // render
  return (
    <SegmentsProvider>
      <div className="segment-container">
        <div className="segment-block">
          <Row>
            <Col md={3} sm={12} className="segment-sidebar">
              <SegmentsSidebar />
            </Col>
            <Col md={9} sm={12} className="segment-content">
              <SegmentsContent mixpanel = {mixpanel} />
            </Col>
          </Row>
        </div>
      </div>
    </SegmentsProvider>
  );
};

Segments.propTypes = {
  mixpanel: PropTypes.object,
};

export default Segments;
