import React, { useState } from 'react';
import GrayAppImg from 'src/img/gray_app.png';
import pulseMIcon from 'src/assets/images/settings/apps/pulsem_vertical.svg';
import tickMark from 'src/assets/images/settings/apps/tick-mark.svg';
import Card from "src/shared/styleguide/card/Card";
import Button from "src/shared/styleguide/button/Button";
import PulseMConnectionModal from './PulseMConnectionModal';
import swal from "sweetalert";
import { GET_APP_INTEGRATIONS } from "src/graphql/settings/apps/queries";
import { UPDATE_APP_INTEGRATION_SETTING } from "src/graphql/settings/apps/mutations";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { showCompanyId } from "src/shared/SessionHelper";
import { APP_INTEGRATIONS } from "src/shared/Constants";
import { formatDate } from "src/shared/Utils";
import { addToast } from "src/utils/ToastUtil";
import CookieUtil from "src/utils/CookieUtil";
import { COOKIES } from "src/shared/Config";
import { GET_COMPANY_SETTINGS } from "src/components/company/settings/graphql_queries/queries.js";
import { EVENT_PM_CONNECTION, EVENT_PM_DISCONNECTION } from "src/constants/MixpanelEvents";


const AppIntegrations = ({ mixpanel }) => {
  // States and variables
  const [pulseMConnected, setPulseMConnected] = useState(false);
  const [pulseMAppIntegration, setPulseMAppIntegration] = useState(null);
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [company, setCompany] = useState(null);
  const client = useApolloClient();

  /**
   * Get API integration data
   */
  const getAppIntegrations = () => {
    client
      .query({
        query: GET_APP_INTEGRATIONS,
        variables: {
          companyId: showCompanyId,
        }
      })
      .then((res) => {
        if (res && res.data) {
          populateData(res.data);
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  /**
   * Get company information
   */
  const getCompany = () => {
    client
      .query({
        query: GET_COMPANY_SETTINGS,
        variables: {
          id: showCompanyId,
        },
      })
      .then((res) => {
        if (res && res.data && res.data.company) {
          setCompany(res.data.company);
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  /**
   * Set the current state of pulseM connection
   * @param {JSON} data -- Connection data
   */
  const populateData = (data) => {
    if (data) {
      let pulseMIntegration = null;
      if (data.appIntegrations) {
        pulseMIntegration = data.appIntegrations.find(app => app.shortName === APP_INTEGRATIONS.pulseM);
      } else if (data.updateAppIntegrationSetting) {
        pulseMIntegration = data.updateAppIntegrationSetting;
      }
      // pulseM Integration.
      if (pulseMIntegration) {
        setPulseMAppIntegration(pulseMIntegration);
        setPulseMConnected(pulseMIntegration.status === "enabled");
      }
    }
  };

  // call api updateConnection
  const [updateConnection, { loading: saving }] = useMutation(
    UPDATE_APP_INTEGRATION_SETTING,
  );

  /**
   * Action handler for pulseM connect action
   */
  const connectPulseM = () => {
    setShowConnectionModal(true);
  }

  /**
   * Action handler for pulseM disconnect action
   */
  const disconnectPulseM = () => {
    swal({
      closeOnClickOutside: false,
      closeOnEsc: false,
      title: "Are you sure?",
      className: "confirm-dialog-settings",
      text: `Do you really want to disconnect from pulseM? You will not be able to add your pulseM reviews to a postcard campaign.`,
      icon: "error",
      buttons: {
        save: {
          text: "Yes, Disconnect",
          value: "disconnect",
        },
        cancel: true,
      },
    })
      .then((confirm) => {
        if (confirm === "disconnect") {
          const params = {
            companyId: showCompanyId,
            shortName: APP_INTEGRATIONS.pulseM,
            status: "disabled",
            metaData: pulseMAppIntegration.metaData
          };
          updateConnectionStatus(params, false);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  /**
   * Updating connection status.
   */
  const updateConnectionStatus = (params, isConnectAction) => {
    let message = ""

    updateConnection({
      variables: params,
    })
      .then((res) => {
        if (res && res.data) {
          setPulseMConnected(false);
          setShowConnectionModal(false);
          populateData(res.data);
        }
        // Connection successful
        message = isConnectAction ? "The connection with pulseM has been successful." : "The connection with pulseM has been disconnected"
        addToast({
          type: "success",
          message: (
            <span>
              {message}
            </span>
          )

        });

        // mixpanel tracking
        if (isConnectAction) {
          mixpanel.track(EVENT_PM_CONNECTION, {
            "Action: Click Connect": "pulseM connection"
          });
        } else {
          mixpanel.track(EVENT_PM_DISCONNECTION, {
            "Action: Click Disconnect": "pulseM disconnection"
          });
        }
      })
      .catch((err) => {
        // Failed to create connection.
        addToast({
          type: "error",
          message: (
            <span>
              The connection with PulseM has been unsuccessful.{" "}
              <a
                onClick={() => {
                  CookieUtil.setCookie(COOKIES.HIDE_DC_TOAST_KEY, "1");
                }}
                className="text-white"
                href={`/companies/${showCompanyId}/help-center`}
              >
                <u>Click here</u>
              </a>{" "}
              to get help.
            </span>
          )
        });
      });
  };

  // effects
  React.useState(() => {
    getAppIntegrations();
    getCompany();
  }, []);

  return (
    <React.Fragment>
      <div className="app-integration-container">
        <div className="mobile-app-container-one">
          <img className="app-img mr-2" src={GrayAppImg} alt="app" />
          <div className='inner-container-one'>
            <div className="large-text">Integrations</div>
            <div className="small-gray-text col-12 pl-0">Customer Lobby is most useful when you can see everything your customers are doing that includes all the tools you use outside of Customer Lobby! Integrations let you automatically pull information and activity from outside tools into Customer Lobby in a way that’s timely, relevant and searchable.</div>
            <Card
              cardBodyStyle={{
                padding: 0,
              }}
              className="integration-card"
            >
              <div className="integration-card-contents">
                <div className="connection-status ">
                  <img src={pulseMIcon} className="pulse-m-icon" alt="pulseM" />
                  {/* Tick mark showing pulseM is connected. */}
                  {pulseMConnected && <img src={tickMark} className="tick-mark" alt="Tick Mark" />}
                  {/* Showing connect and Disconnect as per "pulseMConnected" state */}
                  {pulseMConnected ?
                    <Button
                      variant="outline-primary"
                      className="pull-right"
                      onClick={disconnectPulseM}
                    >
                      Disconnect
                  </Button>
                    :
                    <Button
                      variant="outline-primary"
                      className="pull-right"
                      onClick={connectPulseM}
                    >
                      Connect
                  </Button>
                  }
                </div>

                {pulseMConnected ?
                  <div className="mt-4">
                    <span className="mr-2 status-text">Connected since</span> <span className="connection-date">{formatDate(pulseMAppIntegration.updatedAt, 'L')}</span>
                  </div>
                  : <div className="mt-4 mb-2">
                    <span className="status-text"> Share your pulseM reviews in your campaign content in Customer Lobby. </span>
                  </div>
                }
              </div>

            </Card>
          </div>
        </div>
      </div>
      {showConnectionModal &&
        <PulseMConnectionModal
          setShowConnectionModal={setShowConnectionModal}
          showConnectionModal={showConnectionModal}
          updateConnectionStatus={updateConnectionStatus}
          company={company}
          pulseMAppIntegration={pulseMAppIntegration}
        />
      }
    </React.Fragment>
  )
}

export default AppIntegrations;
