import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { showCompanyId, pulsem } from "src/shared/SessionHelper";
import TabsComponent from "src/shared/tabs/TabsComponent";
import "src/components/settings/reviews/ReviewSettings.scss";
import ReviewWidgetContainer from "src/components/settings/widgets/review_widgets/ReviewWidgetContainer";
import AppointmentWidget from "src/components/settings/widgets/appointment_widgets/AppointmentWidget";
import { WIDGET_SETTINGS } from 'src/graphql/company/queries';
import { showCompanyId as companyId, featureFlags } from "src/shared/SessionHelper";
import { CACHE_AND_NETWORK } from 'src/shared/Constants'
import { useQuery } from "@apollo/react-hooks";
import WordpressWidget from 'src/components/settings/widgets/wordpress_widgets/WordpressWidget'

export const Widgets = ({ mixpanel }) => {
  const queryData = useQuery(WIDGET_SETTINGS, {
    fetchPolicy: CACHE_AND_NETWORK,
    variables: { companyId },
  })

  const tabs = [];

  if (featureFlags.reviewWrite && !pulsem) {
    tabs.push(
      {
        eventKey: 0,
        title: "Reviews",
        path: "reviews",
        documentTitle: "Reviews | Widgets",
        component: <ReviewWidgetContainer widgetSettings={queryData} />
      }
    );
  }
  if (featureFlags.appointmentWrite) {
    tabs.push(
      {
        eventKey: 1,
        title: "Appointment",
        path: "appointment",
        documentTitle: "Appointment | Widget",
        component: <AppointmentWidget widgetSettings={queryData} />
      }
    );
  }
  if (featureFlags.reviewWrite && !pulsem) {
    tabs.push(
      {
        eventKey: 2,
        title: "WordPress",
        path: "wordpress",
        documentTitle: "WordPress | Widget",
        component: <WordpressWidget widgetSettings={queryData} />
      }
    );
  }


  // states
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const history = useHistory();
  const location = useLocation();

  // methods
  const onTabChange = (tabIndex) => {
    const selectedTab = tabs[tabIndex];

    history.push(
      `/companies/${showCompanyId}/widgets/${selectedTab.path}`
    );
    document.title = selectedTab.documentTitle;
    setSelectedTab(selectedTab);
  };

  // effects
  useEffect(() => {
    const selectedTab = tabs.find((tab) =>
      location.pathname.includes(tab.path)
    );
    if (selectedTab) {
      document.title = selectedTab.documentTitle;
      setSelectedTab(selectedTab);
    }
  }, [location]);

  // render
  return (
    <TabsComponent
      className="tab-review-settings settings-tabs"
      unmountOnExit={false}
      onTabChange={onTabChange}
      tabs={tabs}
      selectedTab={selectedTab ? selectedTab.eventKey : 0}
    />
  );
};

export default Widgets;
