import React from "react";
import BlockUi from "react-block-ui";
import { MiniCard } from "src/shared/styleguide/card/MiniCard";
import {
  MINI_CARD_HEIGHT,
  MINI_CARD_CONTENT_HEIGHT,
} from "src/constants/ReviewResults";
import MiniDonutChart from "./MiniDonutChart";
import PropTypes from "prop-types";
import { EVENT_REVIEW_RESULTS_INTERACTIONS } from "src/constants/MixpanelEvents";

const PrivateFeedback = ({
  loading,
  fromDate,
  toDate,
  data,
  companyId,
  filter,
  mixpanel,
}) => {
  return (
    <MiniCard
      popupInfoMsg="This refers to the total number of private feedback submitted for the selected date range. It also shows the distribution of general vs. unhappy feedback among the private feedback."
      title="PRIVATE FEEDBACK"
      viewMore={{
        to: {
          pathname: `/companies/${companyId}/private-feedback`,
          search: `date=${filter.id}`,
        },
        label: "View more",
        onClickViewMore: () => {
          if (mixpanel) {
            mixpanel.track(EVENT_REVIEW_RESULTS_INTERACTIONS, {
              Action: "Click",
              Destination: "Private Feedback",
            });
          }
        },
      }}
    >
      <BlockUi blocking={loading}>
        <div className="px-3">
          <MiniDonutChart
            chartId="privateFeedbackChart"
            height={MINI_CARD_HEIGHT}
            pieSize={MINI_CARD_CONTENT_HEIGHT}
            chartTitle={
              data
                ? data.find((feedback) => feedback.id === "all_feedback").count
                : 0
            }
            chartData={[
              {
                y: data
                  ? data.find((feedback) => feedback.id === "general_feedback")
                      .count
                  : 0,
                name: "General",
                color: "#828ef2",
              },
              {
                y: data
                  ? data.find((feedback) => feedback.id === "unhappy_feedback")
                      .count
                  : 0,
                name: "Unhappy",
                color: "#cacaca",
              },
            ]}
            tooltipFormatter={function() {
              const { data } = this.series;
              const total = data[0].y + data[1].y || 1;
              return `${fromDate ? fromDate : ""}${
                toDate && toDate !== fromDate ? ` to ${toDate}` : ""
              }
                <br />${data[0].name}: ${Math.ceil(data[0].y)} (${Math.ceil(
                (data[0].y * 100) / total,
              )}%)
                <br />${data[1].name}: ${Math.floor(data[1].y)} (${Math.floor(
                (data[1].y * 100) / total,
              )}%)`;
            }}
          />
        </div>
      </BlockUi>
    </MiniCard>
  );
};

PrivateFeedback.propTypes = {
  loading: PropTypes.bool.isRequired,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  data: PropTypes.array,
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object,
};

export default PrivateFeedback;
