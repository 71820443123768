import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApolloClient } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import Button from "src/shared/styleguide/button/Button";
import Card from "src/shared/styleguide/card/Card";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import Form from "src/shared/styleguide/form/Form";
import CustomerInfoImage from "src/assets/images/customer/user-circle.svg";
import LockIcon from "src/assets/images/customer/lock-icon.svg";
import { EVENT_CUSTOMER_INFORMATION_EDIT } from "src/constants/MixpanelEvents";
import { UPDATE_CONTACT_INFORMATION } from "src/graphql/customers_segments/customers/mutations.js";
import { NOT_AVAILABLE, NO_CACHE } from "src/shared/Constants";

import {
  formatPhoneNumberLive,
  sanitizePhoneNumber,
  usPhoneNumberValidation,
} from "src/shared/Utils.js";
import { addToast } from "src/utils/ToastUtil";
import "./CustomerContent.scss";

const CustomerInformationCard = ({
  mixpanel,
  customerData,
  setCustomerData,
  customerInfoDisabled,
}) => {
  // state
  const client = useApolloClient();

  // variables
  const invalidPhoneMessage = "Please enter a valid phone number.";

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: customerData.firstName || "",
      lastName: customerData.lastName || "",
      companyName: customerData.companyName || "",
      email: customerData.email || "",
      phoneMobile: customerData.phoneMobile || "",
      phoneDaytime: customerData.phoneDaytime || "",
      phoneDaytimeExt: customerData.phoneDaytimeExt || "",
      phoneAlt: customerData.phoneAlt || "",
      phoneAltExt: customerData.phoneAltExt || "",
      address1: customerData.address1 || "",
      address2: customerData.address2 || "",
      city: customerData.city || "",
      state: customerData.state || "",
      zip: customerData.zip || "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      companyName: Yup.string(),
      email: Yup.string(),
      phoneMobile: Yup.string().test(
        "phoneMobile",
        invalidPhoneMessage,
        usPhoneNumberValidation,
      ),
      phoneDaytime: Yup.string().test(
        "phoneDaytime",
        invalidPhoneMessage,
        usPhoneNumberValidation,
      ),
      phoneDaytimeExt: Yup.string(),
      phoneAlt: Yup.string().test(
        "phoneAlt",
        invalidPhoneMessage,
        usPhoneNumberValidation,
      ),
      phoneAltExt: Yup.string(),
      address1: Yup.string(),
      address2: Yup.string(),
      city: Yup.string(),
      zip: Yup.string(),
    }),
    onSubmit: (values) => {
      onSaveClick({ ...values });
    },
  });

  // methods
  const onSaveClick = (values) => {
    mixpanel.track(EVENT_CUSTOMER_INFORMATION_EDIT, {
      "Action: Member clicks 'Save Changes'": {
        "Customer Updated": `${customerData.firstName} ${customerData.lastName}`,
        "Contact Id": customerData.id,
      },
    });
    client
      .mutate({
        mutation: UPDATE_CONTACT_INFORMATION,
        variables: {
          ...values,
          contactId: customerData.contactId,
          companyId: customerData.companyId,
        },
        fetchPolicy: NO_CACHE,
      })
      .then((res) => {
        if (res.data && res.data.updateContact) {
          const { data } = res;
          setCustomerData({
            ...customerData,
            address1: data.updateContact.address1,
            address2: data.updateContact.address2,
            city: data.updateContact.city,
            companyName: data.updateContact.companyName,
            email: data.updateContact.email,
            firstName: data.updateContact.firstName,
            lastName: data.updateContact.lastName,
            phoneAlt: data.updateContact.phoneAlt,
            phoneAltExt: data.updateContact.phoneAltExt,
            phoneDaytime: data.updateContact.phoneDaytime,
            phoneDaytimeExt: data.updateContact.phoneDaytimeExt,
            phoneMobile: data.updateContact.phoneMobile,
            state: data.updateContact.state,
            zip: data.updateContact.zip,
          });
          addToast({
            message: "Customer Information has been updated.",
            type: "success",
          });
        }
      })
      .catch((e) => console.log("oops: ", e));
  };

  // render
  return (
    <React.Fragment>
      <Card>
        <div className="customer-card-icon-container">
          <img src={CustomerInfoImage} alt="Communication Preferences" />
        </div>
        <div className="customer-card-content-container">
          <form
            className="users-add-edit-form-container"
            onSubmit={formik.handleSubmit}
          >
            <Row className="customer-card-title">
              <Col sm={12}>
                <h2>Customer Information</h2>
                {customerInfoDisabled && (
                  <React.Fragment>
                    <img
                      className="customer-lock-icon"
                      src={LockIcon}
                      alt="Items are not editable"
                    />
                    <PopupInfo
                      message="Update information in your invoicing system
                  for any changes required."
                      iconClassName="customer-info-icon"
                    />
                  </React.Fragment>
                )}
                <div className="customer-card-subtitle">
                  This section contains basic personal information
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group controlId="formBasicInput1">
                  <Row>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">First Name</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.firstName}
                        onChange={(e) =>
                          formik.setFieldValue("firstName", e.target.value)
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">Last Name</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.lastName}
                        onChange={(e) =>
                          formik.setFieldValue("lastName", e.target.value)
                        }
                      />
                    </Col>
                    {customerData.commercial && (
                      <React.Fragment>
                        <Col xl={6} md={12}>
                          <Form.Label className="text-dark">
                            Company Name
                          </Form.Label>
                          <Form.Control
                            disabled={customerInfoDisabled}
                            placeholder={NOT_AVAILABLE}
                            value={formik.values.companyName}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "companyName",
                                e.target.value,
                              )
                            }
                          />
                        </Col>
                        <Col xl={6} md={12}></Col>
                      </React.Fragment>
                    )}
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">Email</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.email}
                        onChange={(e) =>
                          formik.setFieldValue("email", e.target.value)
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">
                        Mobile Number
                      </Form.Label>
                      <Form.Control
                        className={`${
                          formik.errors.phoneMobile &&
                          formik.touched.phoneMobile
                            ? "is-invalid"
                            : ""
                        }`}
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formatPhoneNumberLive(formik.values.phoneMobile)}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "phoneMobile",
                            sanitizePhoneNumber(e.target.value),
                          );
                        }}
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">
                        Daytime Phone Number
                      </Form.Label>
                      <Form.Control
                        className={`${
                          formik.errors.phoneDaytime &&
                          formik.touched.phoneDaytime
                            ? "is-invalid"
                            : ""
                        }`}
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formatPhoneNumberLive(
                          formik.values.phoneDaytime,
                        )}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "phoneDaytime",
                            sanitizePhoneNumber(e.target.value),
                          )
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">Extension</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.phoneDaytimeExt}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "phoneDaytimeExt",
                            e.target.value,
                          )
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">
                        Alternate Phone Number
                      </Form.Label>
                      <Form.Control
                        className={`${
                          formik.errors.phoneAlt && formik.touched.phoneAlt
                            ? "is-invalid"
                            : ""
                        }`}
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formatPhoneNumberLive(formik.values.phoneAlt)}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "phoneAlt",
                            sanitizePhoneNumber(e.target.value),
                          )
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">Extension</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.phoneAltExt}
                        onChange={(e) =>
                          formik.setFieldValue("phoneAltExt", e.target.value)
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">Address 1</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.address1}
                        onChange={(e) =>
                          formik.setFieldValue("address1", e.target.value)
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">Address 2</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.address2}
                        onChange={(e) =>
                          formik.setFieldValue("address2", e.target.value)
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">City</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.city}
                        onChange={(e) =>
                          formik.setFieldValue("city", e.target.value)
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">State</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.state}
                        onChange={(e) =>
                          formik.setFieldValue("state", e.target.value)
                        }
                      />
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Label className="text-dark">ZIP code</Form.Label>
                      <Form.Control
                        disabled={customerInfoDisabled}
                        placeholder={NOT_AVAILABLE}
                        value={formik.values.zip}
                        onChange={(e) =>
                          formik.setFieldValue("zip", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                  {!customerInfoDisabled && (
                    <div className="mt-4">
                      <Button
                        size="lg"
                        variant="primary"
                        disabled={!formik.isValid || !formik.dirty}
                        type="submit"
                      >
                        Save Changes
                      </Button>
                      <Button
                        size="lg"
                        className="ml-3"
                        variant="outline-secondary"
                        onClick={() => {
                          formik.handleReset();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </form>
        </div>
      </Card>
    </React.Fragment>
  );
};

CustomerInformationCard.propTypes = {
  mixpanel: PropTypes.object,
  customerData: PropTypes.object,
  setCustomerData: PropTypes.func,
  customerInfoDisabled: PropTypes.bool,
};

export default CustomerInformationCard;
