import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { PageHeader } from "src/shared/styleguide/common/PageHeader";
import TabsComponent from "src/shared/tabs/TabsComponent";
import { showCompanyId } from "src/shared/SessionHelper.js";
import "./CustomerSegmentsContainer.scss";
import { MANAGE_CUSTOMER_TABS } from "src/shared/Constants";
import Customers from "./customers/Customers";
import CreateNewButton from "./CreateNewButton";
import AddCustomers from "./AddCustomers";
import { NO_CACHE } from "src/shared/Constants.js";
import { QUERY_DIRECT_CONNECTION_STATUS } from "src/graphql/campaigns/queries.js";
import { showCompanyId as companyId } from "src/shared/SessionHelper.js";
import Segments from "src/components/customers_segments/segments/Segments";
import { QUERY_CONTACTS_LIST } from "src/graphql/customers_segments/customers/queries.js";

export const CustomerSegmentsContainer = ({ tab, mixpanel }) => {
  // states
  const [directConnectSettings, setDirectConnectSettings] = useState("");
  const [directConnectLastRunDate, setDirectConnectLastRunDate] = useState("");
  const [showAddCustomersModal, setShowAddCustomersModal] = React.useState(
    false,
  );
  const [totalCustomersFound, setTotalCustomersFound] = useState(0);

  // queries
  useQuery(QUERY_DIRECT_CONNECTION_STATUS, {
    variables: { id: companyId },
    fetchPolicy: NO_CACHE,
    onCompleted: (data) => {
      if (data && data.directConnectSettings) {
        setDirectConnectLastRunDate(data.directConnectSettings.lastRunDate);
        setDirectConnectSettings(data.directConnectSettings.linkEstablished);
      }
    },
  });

  useQuery(QUERY_CONTACTS_LIST, {
    variables: { companyId: companyId, communicationInfo: "all" },
    fetchPolicy: NO_CACHE,
    onCompleted: (data) => {
      if (data && data.contacts) {
        setTotalCustomersFound(data.contacts.total);
      }
    },
    onError: (err) => {
      console.error("err: ", err);
    },
  });

  // variables
  const tabs = [
    {
      eventKey: MANAGE_CUSTOMER_TABS.customers.eventKey,
      title: MANAGE_CUSTOMER_TABS.customers.title,
      pageHeader: MANAGE_CUSTOMER_TABS.customers.pageHeader,
      path: MANAGE_CUSTOMER_TABS.customers.path,
      documentTitle: MANAGE_CUSTOMER_TABS.customers.documentTitle,
      component: (
        <Customers
          mixpanel={mixpanel}
          showAddCustomersModal={showAddCustomersModal}
          setShowAddCustomersModal={setShowAddCustomersModal}
          directConnectSettings={directConnectSettings}
          directConnectLastRunDate={directConnectLastRunDate}
        />
      ),
    },
    {
      eventKey: MANAGE_CUSTOMER_TABS.segments.eventKey,
      title: MANAGE_CUSTOMER_TABS.segments.title,
      pageHeader: MANAGE_CUSTOMER_TABS.segments.pageHeader,
      path: MANAGE_CUSTOMER_TABS.segments.path,
      documentTitle: MANAGE_CUSTOMER_TABS.segments.documentTitle,
      component: <Segments mixpanel={mixpanel} />,
    },
  ];

  const [selectedTab, setSelectedTab] = React.useState(tabs[0]);
  const history = useHistory();
  const prevPage =
    history.location.state &&
    history.location.state.prevPage &&
    history.location.state.prevPage.includes("customers")
      ? history.location.state.prevPage
      : "";
  const headerRef = React.useRef({ prevPage: prevPage });
  const usePrevPage =
    headerRef && headerRef.current && !!headerRef.current.prevPage;

  // effects
  React.useEffect(() => {
    if (tab) {
      tabs.forEach((tabOption, index) => {
        if (tabOption.path === tab) {
          onTabChange(index);
        }
      });
    }
    setSelectedTab(tabs[tab]);
  }, []);

  // methods
  const onTabChange = (tabIndex, evt) => {
    const selectedTab = tabs[tabIndex];
    history.push(`/companies/${showCompanyId}/${selectedTab.path}`);
    document.title = selectedTab.documentTitle;
    setSelectedTab(selectedTab);
  };

  // render
  return (
    <React.Fragment>
      <PageHeader
        className="customers-header"
        showArrow={usePrevPage}
        onArrowClick={() => {
          history.push(usePrevPage ? headerRef.current.prevPage : "#");
        }}
        leftComponent={selectedTab ? selectedTab.pageHeader : null}
        rightComponent={
          <CreateNewButton
            setShowAddCustomersModal={setShowAddCustomersModal}
            mixpanel={mixpanel}
          />
        }
      />
      <TabsComponent
        className="tabs-customers"
        unmountOnExit={true}
        tabs={tabs}
        selectedTab={selectedTab ? selectedTab.eventKey : null}
        onTabChange={(tabIndex) => onTabChange(tabIndex)}
      />
      <AddCustomers
        directConnectLastRunDate={directConnectLastRunDate}
        directConnectSettings={directConnectSettings}
        mixpanel={mixpanel}
        setShowAddCustomersModal={setShowAddCustomersModal}
        showAddCustomersModal={showAddCustomersModal}
        totalCustomers={totalCustomersFound}
      />
    </React.Fragment>
  );
};

CustomerSegmentsContainer.propTypes = {
  mixpanel: PropTypes.object,
  tab: PropTypes.string,
};

export default CustomerSegmentsContainer;
