import React, { useState, useEffect, useRef } from "react";
import TextingAccordion from "src/shared/styleguide/accordions/TextingAccordion";
import { SEGMENTS } from "src/graphql/texting/queries";
import { EVENT_ADD_OR_REMOVE_SEGMENT } from "src/constants/MixpanelEvents";
import { ADD_CONTACT_TO_FILTER } from "src/graphql/texting/mutations";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { CACHE_AND_NETWORK, NO_CACHE } from "src/shared/Constants";
import { showCompanyId as companyId } from "src/shared/SessionHelper";
import { addToast } from "src/utils/ToastUtil";
import "./CustomersSegments.scss";

const Segment = ({ segment }) => {
  const cleanSegmentName = (name) => {
    return name ? name.replace(/_/g, " ") : name;
  };

  return (
    <div>
      <div className="segment-content-container">
        <div className="segment-content">{cleanSegmentName(segment.name)}</div>
      </div>
    </div>
  );
};

const CustomersSegments = ({
  mixpanel,
  contactId,
  contact = { contactDisplayName: "" },
}) => {
  const [segmentsLength, setSegmentsLength] = useState(5);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filters, setFilters] = useState([]);
  const [segments, setSegments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [options, setOptions] = useState([]);
  const cancelBtnEl = useRef(null);
  const [addContactToFilter] = useMutation(ADD_CONTACT_TO_FILTER);

  const res = useQuery(SEGMENTS, {
    variables: { contactId, companyId },
    fetchPolicy: CACHE_AND_NETWORK,
  });

  const cleanSegmentName = (name) => {
    return name ? name.replace(/_/g, " ") : name;
  };

  const updateOptions = (currSegments, currFilters) => {
    const filterIds = currSegments.map((segment) => String(segment.id));
    const newOptions = [];
    currFilters.map((filter) => {
      if (!filterIds.includes(String(filter.id))) {
        newOptions.push({
          label: (
            <React.Fragment>
              <span className="filter-label-name">
                {cleanSegmentName(filter.name)}
              </span>{" "}
              <span className="filter-label-count">
                ({filter.customersCount.toLocaleString()} Subscribers)
              </span>
            </React.Fragment>
          ),
          value: filter,
        });
      }
    });
    setOptions(newOptions);
  };

  useEffect(() => {
    if (
      !res.loading &&
      res.data &&
      res.data.getContactsSegments &&
      res.data.filters
    ) {
      setSegments(res.data.getContactsSegments);
      setFilters(res.data.filters);
      updateOptions(res.data.getContactsSegments, res.data.filters);
    }
  }, [res]);

  const handleInputChange = (option) => {
    setSelectedOption(option);
  };

  const closePopover = () => {
    cancelBtnEl.current.click();
    setSelectedOption(null);
  };

  const handleAddMoreClick = () => {
    setSegmentsLength(segmentsLength + 3);
  };

  const addSegment = async () => {
    setSubmitting(true);
    const segmentName = cleanSegmentName(selectedOption.value.name);
    try {
      const res = await addContactToFilter({
        variables: {
          contactId,
          filterId: selectedOption.value.id,
          additionType: "inclusion",
        },
        fetchPolicy: NO_CACHE,
      });

      if (!res.loading && res.data && res.data.addContactToFilter) {
        setSegments(res.data.addContactToFilter);
        updateOptions(res.data.addContactToFilter, filters);
        addToast({
          type: "success",
          message: `Customer successfully added to ${segmentName}`,
        });
        mixpanel.track(EVENT_ADD_OR_REMOVE_SEGMENT, {
          "Customer Name": contact.contactDisplayName,
          "Segment Name": cleanSegmentName(selectedOption.value.name),
        });

        setSelectedOption(null);
        cancelBtnEl.current.click();
      }

      if (!res.loading) setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
    }
  };
  return (
    <div className="customer-segments-container">
      <TextingAccordion
        label="Segments"
        eventKey="1"
        isDefaultActiveKey={false}
        labelStyles={{
          fontSize: "1.2rem",
          color: "#313541",
        }}
      >
        <div className="d-flex flex-wrap">
          {segments.slice(0, segmentsLength).map((segment, idx) => (
            <Segment key={`${idx}-${segment.name}`} segment={segment} />
          ))}
          {segments.length > 5 && segments.length > segmentsLength ? (
            <div onClick={handleAddMoreClick} className="segments-blue-txt">
              + View more
            </div>
          ) : segments.length < 6 ? null : (
            <div
              onClick={() => setSegmentsLength(5)}
              className="segments-blue-txt"
            >
              - View less
            </div>
          )}
        </div>
        {/* Disabling functionality, but leaving artifact for future reference */}
        {/* <PopupCard
          placement="left"
          tooltipClassName="segments-form-tooltip"
          body={
            <Form.Group
              className="segment-form-container"
              controlId="CustomersSegments"
            >
              <Form.Label className="segment-form-label">
                Customer Segment
              </Form.Label>
              <Form.Select
                value={selectedOption}
                onChange={handleInputChange}
                options={options}
                placeholder="Select Customer Segment"
                menuBuffer={30}
                searchable={false}
                clearable={false}
              />
              {selectedOption &&
              selectedOption.value &&
              selectedOption.value.explanation ? (
                <div className="information-container d-flex">
                  <PopupInfo />
                  <div className="segment-form-info-content">
                    {selectedOption.value.explanation}
                  </div>
                </div>
              ) : null}
                <div className="save-segment-button-container">
                <Button
                  variant="primary"
                  disabled={submitting || !selectedOption}
                  className="segment-form-save"
                  onClick={addSegment}
                >
                  Save
                </Button>
                <Button
                  onClick={closePopover}
                  className="segment-form-cancel"
                  variant="secondary"
                >
                  Cancel
                </Button>
              </div>
            </Form.Group>
          }
        >
          <Button
            variant="secondary"
            className="add-segment-btn"
            ref={cancelBtnEl}
          >
            + Add
          </Button>
        </PopupCard> */}
      </TextingAccordion>
    </div>
  );
};

CustomersSegments.propTypes = {
  contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mixpanel: PropTypes.object.isRequired,
  contact: PropTypes.object,
};

export default CustomersSegments;
