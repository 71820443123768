import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import CustomersContent from "./CustomersContent";
import { CustomersProvider } from "./CustomersContext";
import CustomersSidebar from "./CustomersSidebar";
import "./Customers.scss";

const Customers = ({
  mixpanel,
  setShowAddCustomersModal,
  directConnectSettings,
  directConnectLastRunDate,
}) => {
  // render
  return (
    <CustomersProvider>
      <div className="customer-container">
        <div className="customer-block">
          <Row>
            <Col md={3} sm={12} className="customer-sidebar">
              <CustomersSidebar
                directConnectSettings={directConnectSettings}
                directConnectLastRunDate={directConnectLastRunDate}
                mixpanel={mixpanel}
              />
            </Col>
            <Col md={9} sm={12} className="customer-content">
              <CustomersContent
                mixpanel={mixpanel}
                setShowAddCustomersModal={setShowAddCustomersModal}
              />
            </Col>
          </Row>
        </div>
      </div>
    </CustomersProvider>
  );
};

Customers.propTypes = {
  mixpanel: PropTypes.object,
  setShowAddCustomersModal: PropTypes.func,
};

export default Customers;
