import React, { Component } from "react";
import "./Header.scss";
import logo from "src/img/header/logo.png";
import * as sessionHelper from "src/shared/SessionHelper";
import * as config from "src/shared/Config";
import classnames from "classnames";
import privateFeedbackIcon from "src/assets/images/header/private_feedback.svg";
import trackReviewsIcon from "src/assets/images/header/track_reviews.svg";
import reviewsSettingsIcon from "src/assets/images/header/reviews_settings.svg";
import getReviewsIcon from "src/assets/images/header/get_reviews.svg";
import pulsemIcon from "src/assets/images/header/pulsem.svg";
import manageReviewsIcon from "src/assets/images/header/manage_reviews.svg";
import reviewResultsIcon from "src/assets/images/header/review_results.svg";
import segmentsIcon from "src/assets/images/header/segment_menu_icon.svg";
import customersIcon from "src/assets/images/header/customers_menu.svg";
import campaignIcon from "src/assets/images/header/campaigns.svg";
import reviewsicon from "src/assets/images/header/reviews.svg";
import companyProfile from "src/img/header/company-profile.png";
import billing from "src/img/header/billing.png";
import widget from "src/img/header/widget.png";
import { connect } from "react-redux";
import users from "src/img/header/users.png";
import directConnect from "src/img/header/direct-connect.png";
import manageCampaigns from "src/img/header/manage-icon.svg";
import information from "src/img/header/information.png";
import user from "src/img/header/user.png";
import profile from "src/img/header/profile.png";
import logout from "src/img/header/logout.png";
import {
  setCompanyDetails,
  setSubscriptionDetails,
} from "src/redux/companySlice";
import {
  HEADER_MENU,
  MANAGE_REVIEW_TABS,
  MANAGE_CAMPAIGN_TABS,
  MANAGE_CUSTOMER_TABS,
} from "src/shared/Constants";
import cookie from "react-cookies";
import { LOGOUT_USER } from "../graphql/mutations";
import { gql } from "apollo-boost";
import { MixpanelConsumer } from "react-mixpanel";
import {
  EVENT_PM_LOGIN,
  EVENT_VIEWED_REVIEW_RESULTS,
  EVENT_VIEW_CAMPAIGN_MANAGE,
  EVENT_VIEW_CAMPAIGN_RESULTS,
  EVENT_VIEW_CUSTOMERS,
  EVENT_VIEW_SEGMENTS,
} from "src/constants/MixpanelEvents";
import PopupInfo from "src/shared/styleguide/common/PopupInfo";
import { COMPANY_SETTINGS_ROUTES } from "src/shared/Constants";
import { QUERY_SUBSCRIPTION_DETAILS } from "src/graphql/campaigns/postcard/queries";
import {
  featureFlags,
  showIsManager,
  userType,
} from "src/shared/SessionHelper";
import { ManagerAuthBlock } from "src/shared/auth/AuthBlock";
import { QUERY_MESSAGING_COUNTS } from "src/graphql/texting/queries";
import SettingsContext from "src/shared/context/SettingsContext";
import { GET_STARTED_COMPANY_STATUS } from "src/graphql/service_fusion/queries";
import HelpAndGetStarted from "src/components/service_fusion/HelpAndGetStarted";
import { COMPANY_ACCOUNT } from "src/shared/Config";
import { EVENT_HELP_LEARNING_CENTRE } from "src/constants/MixpanelEvents";
import { showCompanyId, pulsem } from "src/shared/SessionHelper";
import { acceptsTerms } from "src/shared/SessionHelper";

const enhanceWithClickOutside = require("react-click-outside");

const USER_TYPE = "1";
const reviewMenuOptions = [
  {
    id: 1,
    label: "Results",
    icon: reviewResultsIcon,
    description: "Performance indicators at a glance",
    url: `/${config.COMPANIES_TOKEN}/${MANAGE_REVIEW_TABS.results.path}`,
  },
  {
    id: 2,
    label: "Manage",
    icon: manageReviewsIcon,
    description: "View and manage your reviews",
    url: `/${config.COMPANIES_TOKEN}/${MANAGE_REVIEW_TABS.manage.path}`,
  },
  {
    id: 3,
    label: "Track",
    icon: trackReviewsIcon,
    description: "Track your review invitations",
    url: `/${config.COMPANIES_TOKEN}/${MANAGE_REVIEW_TABS.track.path}`,
  },
  {
    id: 4,
    label: "Private Feedback",
    icon: privateFeedbackIcon,
    description: "View customer suggestions",
    url: `/${config.COMPANIES_TOKEN}/${MANAGE_REVIEW_TABS.privateFeedback.path}`,
  },
  {
    id: 0,
    label: "Get Reviews",
    icon: getReviewsIcon,
    description: "Send your customers review invitations",
    url: `/${config.COMPANIES_TOKEN}/${MANAGE_REVIEW_TABS.getReviews.path}`,
  },
];
const menuOptions = [];
if (featureFlags.campaignRead) {
  menuOptions.push({
    id: 1,
    label: "Campaigns",
    icon: campaignIcon,
    description: "Manage Retention Campaigns",
    url: `/${config.COMPANIES_TOKEN}/${MANAGE_CAMPAIGN_TABS.manage.path}`,
  });
}
if (featureFlags.reviewRead && !pulsem) {
  menuOptions.push({
    id: 0,
    label: "Reviews",
    icon: reviewsicon,
    description: "Manage Customer Lobby Reviews",
    url: `/${config.COMPANIES_TOKEN}/${MANAGE_REVIEW_TABS.getReviews.path}`,
  });
}
if (pulsem) {
  menuOptions.push({
    id: 5,
    label: "Reviews",
    icon: reviewsicon,
    description: "Access to your pulseM reviews from here",
    url: "https://dashboard.pulsem.me/login",
    pulsemReview: true,
  });
}

if (featureFlags.messageRead) {
  menuOptions.push({
    id: 2,
    label: "Messages",
    icon: getReviewsIcon,
    description: "Send and Manage Messages",
    url: `/companies/${showCompanyId}/${COMPANY_SETTINGS_ROUTES.messaging}`,
  });
}

if (featureFlags.appointmentRead && !featureFlags.customAppointmentWrite) {
  menuOptions.push({
    id: 4,
    label: "Appointments",
    icon: reviewResultsIcon,
    description: "View Appointments",
    url: `/${config.COMPANIES_TOKEN}/appointments`,
  });
}
menuOptions.push({
  id: 3,
  label: "Customers",
  icon: manageReviewsIcon,
  description: "Manage Customers and Segments",
  url: `/${config.COMPANIES_TOKEN}/${MANAGE_CUSTOMER_TABS.segments.path}`,
});

// TODO: eventually use header from the styleguide
class Header extends Component {
  static contextType = SettingsContext;
  constructor(props, context) {
    super(props, context);

    var userName = cookie.load("user_name");
    var userFirstName = userName
      ? userName.includes("+")
        ? userName.substring(0, userName.indexOf("+"))
        : userName
      : "";
    var userLastName = userName
      ? userName.includes("+")
        ? userName.substring(userName.indexOf("+") + 1, userName.length)
        : userName
      : "";
    var userEmail = cookie.load("company_user_email");
    var userId = cookie.load("user_id");
    var companyId = cookie.load("company_id");
    var ghostUser = cookie.load("ghost_user");
    this.state = {
      showUserMenu: false,
      reviewMenu: false,
      CustomerMarketingMenu: false,
      campaignMenu: false,
      settingsMenu: false,
      ghostUser,
      userId,
      companyId,
      usersMenu: false,
      appointmentMenu: false,
      showHambugerMenu: false,
      customersMenu: false,
      companyAccountClass: sessionHelper.loadCookie(
        config.COOKIES.ACCOUNT_CLASS,
      ),
      partnerCompanyId: sessionHelper.loadCookie(
        config.COOKIES.PARTNER_COMPANY_ID,
      ),
      partnerUserId: sessionHelper.loadCookie(config.COOKIES.PARTNER_USER_ID),
      companyAccountId: sessionHelper.loadCookie(
        config.COOKIES.ACCOUNT_LEVEL_ID,
      ),
      token: sessionHelper.loadCookie(config.COOKIES.AUTH_TOKEN),
      userName: cookie.load("user_name")
        ? cookie.load("user_name").replace("+", " ")
        : "",
      showHelp: false,
      companyStatusData: null,
    };
  }

  componentDidMount() {
    this.fetchMessagingCount();
    this.fetchCompanyStatus();
    this.fetchCompanySubscription();
  }

  fetchCompanySubscription() {
    this.props.client
      .query({
        variables: { companyId: showCompanyId },
        query: QUERY_SUBSCRIPTION_DETAILS,
        context: { headers: { AUTHORIZATION: this.props.token } },
      })
      .then((response) => {
        if (response && response.data) {
          this.props.dispatch(
            setSubscriptionDetails(response?.data?.subscriptionDetails),
          );
        }
      })
      .catch(() => {
        // do nothing
      })
      .finally(() => {});
  }

  fetchCompanyStatus() {
    this.props.client
      .query({
        variables: { companyId: showCompanyId },
        query: GET_STARTED_COMPANY_STATUS,
        context: { headers: { AUTHORIZATION: this.props.token } },
      })
      .then((response) => {
        if (response && response.data) {
          this.populateData(response.data);
          this.props.dispatch(setCompanyDetails(response?.data?.company));
        }
      })
      .catch(() => {
        // do nothing
      })
      .finally(() => {});
  }

  populateData(data) {
    this.setState({ companyStatusData: data });
  }

  /**
   * Fetch messaging count
   */
  fetchMessagingCount() {
    this.props.client
      .query({
        query: QUERY_MESSAGING_COUNTS,
        variables: {
          companyId: this.state.companyId,
        },
      })
      .then((res) => {
        if (res && res.data && res.data.messagingCounts) {
          this.setState({
            unreadMessages: res.data.messagingCounts.unreadMessages,
          });
        }
      })
      .catch(() => {
        // do nothing
      });
  }

  /**
   * Handle post logout link
   */
  redirectToLogin() {
    window.location.href = "/company/login";
  }

  /**
   * Provide logged in user name
   */
  getUserName() {
    if (sessionHelper.getUserFirstName() && sessionHelper.getUserLastName()) {
      return (
        sessionHelper.getUserFirstName() + " " + sessionHelper.getUserLastName()
      );
    } else if (
      sessionHelper.getUserFirstName() &&
      !sessionHelper.getUserLastName()
    ) {
      return sessionHelper.getUserFirstName();
    } else if (
      !sessionHelper.getUserFirstName() &&
      sessionHelper.getUserLastName()
    ) {
      return sessionHelper.getUserLastName();
    } else {
      return sessionHelper.getUserFirstName();
    }
  }
  handleCloseMenu = () => {
    if (this.state.CustomerMarketingMenu) {
      this.setState({ CustomerMarketingMenu: false });
    }
  };

  showMenu = (menu) => {
    switch (menu) {
      case HEADER_MENU.settings:
        this.setState({
          reviewMenu: false,
          campaignMenu: false,
          settingsMenu: !this.state.settingsMenu,
          usersMenu: false,
          appointmentMenu: false,
          customersMenu: false,
        });
        break;
      case HEADER_MENU.reviews:
        this.setState({
          reviewMenu: !this.state.reviewMenu,
          campaignMenu: false,
          settingsMenu: false,
          usersMenu: false,
          appointmentMenu: false,
          customersMenu: false,
        });
        break;
      case HEADER_MENU.CustomerMarketing:
        this.setState(
          {
            CustomerMarketingMenu: !this.state.CustomerMarketingMenu,
            campaignMenu: false,
            reviewMenu: false,
            appointmentMenu: false,
            customersMenu: false,
          },
          () => {
            document.addEventListener("click", this.handleCloseMenu);
          },
        );
        break;
      case HEADER_MENU.campaigns:
        this.setState({
          reviewMenu: false,
          campaignMenu: !this.state.campaignMenu,
          settingsMenu: false,
          usersMenu: false,
          appointmentMenu: false,
          customersMenu: false,
        });
        break;
      case HEADER_MENU.users:
        this.setState({
          reviewMenu: false,
          campaignMenu: false,
          settingsMenu: false,
          usersMenu: !this.state.usersMenu,
          appointmentMenu: false,
          customersMenu: false,
        });
        break;
      case HEADER_MENU.appointments:
        this.setState({
          reviewMenu: false,
          campaignMenu: false,
          settingsMenu: false,
          usersMenu: false,
          appointmentMenu: !this.state.appointmentMenu,
          customersMenu: false,
        });
        break;
      case HEADER_MENU.customers:
        this.setState({
          reviewMenu: false,
          campaignMenu: false,
          settingsMenu: false,
          usersMenu: false,
          customersMenu: !this.state.customersMenu,
        });
        break;
      default:
        this.setState({
          reviewMenu: false,
          campaignMenu: false,
          settingsMenu: false,
          usersMenu: false,
          appointmentMenu: false,
          customersMenu: false,
        });
        break;
    }
  };
  componentDidUpdate() {
    document.removeEventListener("click", this.handleCloseMenu);
  }

  showHamburgerMenu = () => {
    this.setState({ showHambugerMenu: !this.state.showHambugerMenu });
  };

  logout = () => {
    this.props.client.cache.reset();
    this.props.client
      .mutate({
        mutation: gql`
          ${LOGOUT_USER}
        `,
        variables: { token: `${this.state.token}` },
        context: { headers: { AUTHORIZATION: this.state.token } },
      })
      .then(() => {
        sessionHelper.removeCookies();
        sessionStorage.clear();
        Object.keys(localStorage).forEach((key) => {
          if (!key.startsWith("csb-")) {
            localStorage.removeItem(key);
          }
        });
        window.location.href = config.MEMBERS_URLS.login;
      })
      .catch((error) => {
        sessionHelper.removeCookies();
        window.location.href = config.MEMBERS_URLS.login;
      });
  };

  render() {
    let isManager = sessionHelper.getUserType() === USER_TYPE ? true : false;
    let classes = classnames(
      "dropdown-menu dropdown-user dropdown-menu-right",
      { show: this.state.showUserMenu },
    );
    // variables from setting context.
    const { showLearningCenter, activeTabForLearningCenter } = this.context;

    function getLocation() {
      const currentUrl = window.location.href;
      let location = "Application";
      // IF statement checking
      if (currentUrl.includes("/campaigns/manage")) {
        location = "Manage Campaigns";
      } else if (currentUrl.includes("/settings")) {
        location = "Settings";
      }
      return location;
    }

    return (
      <MixpanelConsumer>
        {(mixpanel) => (
          <div
            className="fixed-top navbar navbar-light navbar-static-top navbar-expand-xl"
            id="top-header-section"
          >
            <div className="container-fluid">
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target=".navbar-collapse"
                onClick={this.showHamburgerMenu}
              >
                ☰
              </button>
              <a
                className="navbar-brand"
                href={`/companies/${this.state.companyId}/${COMPANY_SETTINGS_ROUTES.dashboard}`}
              >
                <img src={logo} className="logo-image" alt="Customer Lobby" />
              </a>
              <div
                className={`navbar-collapse collapse ${
                  this.state.showHambugerMenu ? "show-menu" : ""
                }`}
              >
                <ul className="nav navbar-nav">
                  <li className="nav-item">
                    <a
                      href={`/companies/${this.state.companyId}/${COMPANY_SETTINGS_ROUTES.dashboard}`}
                      className="nav-link"
                    >
                      Dashboard
                    </a>
                  </li>
                  {featureFlags.prospectMarketingWrite ? (
                    <>
                      <li className="dropdown menu-large nav-item">
                        <a
                          className="dropdown-toggle nav-link"
                          data-toggle="dropdown"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.showMenu(HEADER_MENU.CustomerMarketing);
                          }}
                        >
                          {" "}
                          Customer Marketing{" "}
                        </a>
                        <ul
                          className={`dropdown-menu megamenu coustmer-markting-menu${
                            this.state.CustomerMarketingMenu ? " show-menu" : ""
                          }`}
                        >
                          <div className="row">
                            {menuOptions.map((menuOption) => (
                              <li key={menuOption.id} className="col-md-6">
                                <div className="dropdown-item">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      if (menuOption.pulsemReview) {
                                        e.preventDefault();
                                        window.open(menuOption.url, "_blank");
                                      }
                                      if (menuOption.id === 1) {
                                        mixpanel.track(
                                          EVENT_VIEWED_REVIEW_RESULTS,
                                          {
                                            from: "menu",
                                          },
                                          null,
                                          () => {
                                            window.location = menuOption.url;
                                          },
                                        );
                                      }
                                      if (!menuOption.pulsemReview) {
                                        window.location = menuOption.url;
                                      }
                                    }}
                                  >
                                    <div className="pull-left">
                                      <img
                                        src={menuOption.icon}
                                        className="menu-icon"
                                      />
                                    </div>
                                    <div className="menu-text">
                                      <div className="menu-header">
                                        {menuOption.label}
                                      </div>
                                      <div className="menu-sub-title">
                                        {menuOption.description}
                                      </div>
                                      {menuOption.pulsemReview ? (
                                        <div className="mt-2">
                                          <span className="powered">
                                            Powered by
                                          </span>
                                          <img
                                            src={pulsemIcon}
                                            className="pulsemicon"
                                            alt="pulsem"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </a>
                                </div>
                              </li>
                            ))}
                          </div>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <>
                      {this.state.companyAccountClass !==
                        COMPANY_ACCOUNT.INSIGHT && (
                        <React.Fragment>
                          {featureFlags.messageRead && !pulsem ? (
                            <li className="nav-item">
                              <a
                                href={`/companies/${this.state.companyId}/${COMPANY_SETTINGS_ROUTES.messaging}`}
                                className="nav-link"
                              >
                                Messages{" "}
                                {this.state.unreadMessages &&
                                this.state.unreadMessages > 0 ? (
                                  <span
                                    id="unread-messages-count"
                                    className="unread_messages_count"
                                  >
                                    {this.state.unreadMessages > 99 ? (
                                      <small>99+</small>
                                    ) : (
                                      this.state.unreadMessages
                                    )}
                                  </span>
                                ) : null}
                              </a>
                            </li>
                          ) : null}
                          <li className="dropdown menu-large nav-item">
                            <a
                              className={`dropdown-toggle nav-link ${
                                this.state.customersMenu
                                  ? " nav-link-active"
                                  : ""
                              }`}
                              data-toggle="dropdown"
                              onClick={() =>
                                this.showMenu(HEADER_MENU.customers)
                              }
                            >
                              {" "}
                              Customers{" "}
                            </a>
                            <ul
                              className={`dropdown-menu megamenu campaign-menu${
                                this.state.customersMenu ? " show-menu" : ""
                              }`}
                            >
                              <li className="dropdown-item">
                                <a
                                  href="#"
                                  onClick={() => {
                                    mixpanel.track(
                                      EVENT_VIEW_CUSTOMERS,
                                      {
                                        from: "menu",
                                      },
                                      null,
                                      () => {
                                        window.location = `/${config.COMPANIES_TOKEN}/${MANAGE_CUSTOMER_TABS.customers.path}`;
                                      },
                                    );
                                  }}
                                >
                                  <div className="pull-left">
                                    <img
                                      src={customersIcon}
                                      alt="Customers"
                                      className="menu-icon"
                                    />
                                  </div>
                                  <div className="menu-text">
                                    <div className="menu-header">Customers</div>
                                    <div className="menu-sub-title">
                                      View all your customers
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li className="dropdown-item">
                                <a
                                  href="#"
                                  onClick={() => {
                                    mixpanel.track(
                                      EVENT_VIEW_SEGMENTS,
                                      {
                                        from: "menu",
                                      },
                                      null,
                                      () => {
                                        window.location = `/${config.COMPANIES_TOKEN}/${MANAGE_CUSTOMER_TABS.segments.path}`;
                                      },
                                    );
                                  }}
                                >
                                  <div className="pull-left">
                                    <img
                                      src={segmentsIcon}
                                      alt="Segments"
                                      className="menu-icon"
                                    />
                                  </div>

                                  <div className="menu-text">
                                    <div className="menu-header">Segments</div>
                                    <div className="menu-sub-title">
                                      Create, edit and view all segments
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </li>
                          {featureFlags.campaignRead && (
                            <li className="dropdown menu-large nav-item">
                              <a
                                className={`dropdown-toggle nav-link ${
                                  this.state.campaignMenu
                                    ? " nav-link-active"
                                    : ""
                                }`}
                                data-toggle="dropdown"
                                onClick={() =>
                                  this.showMenu(HEADER_MENU.campaigns)
                                }
                              >
                                {" "}
                                Campaigns{" "}
                              </a>
                              <ul
                                className={`dropdown-menu megamenu campaign-menu${
                                  this.state.campaignMenu ? " show-menu" : ""
                                }`}
                              >
                                <li className="dropdown-item">
                                  <a
                                    href="#"
                                    onClick={() => {
                                      mixpanel.track(
                                        EVENT_VIEW_CAMPAIGN_RESULTS,
                                        {
                                          from: "menu",
                                        },
                                        null,
                                        () => {
                                          window.location = `/${config.COMPANIES_TOKEN}/${MANAGE_CAMPAIGN_TABS.results.path}`;
                                        },
                                      );
                                    }}
                                  >
                                    <div className="pull-left">
                                      <img
                                        src={reviewResultsIcon}
                                        className="menu-icon"
                                        alt="Review Results"
                                      />
                                    </div>
                                    <div className="menu-text">
                                      <div className="menu-header">Results</div>
                                      <div className="menu-sub-title">
                                        View your overall campaign results
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li className="dropdown-item">
                                  <a
                                    href="#"
                                    onClick={() => {
                                      mixpanel.track(
                                        EVENT_VIEW_CAMPAIGN_MANAGE,
                                        {
                                          from: "menu",
                                        },
                                        null,
                                        () => {
                                          window.location = `/${config.COMPANIES_TOKEN}/${MANAGE_CAMPAIGN_TABS.manage.path}`;
                                        },
                                      );
                                    }}
                                  >
                                    <div className="pull-left">
                                      <img
                                        src={manageCampaigns}
                                        alt="Manage Campaigns"
                                        className="menu-icon"
                                      />
                                    </div>

                                    <div className="menu-text">
                                      <div className="menu-header">Manage</div>
                                      <div className="menu-sub-title">
                                        Create, edit and view all your campaigns
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          )}

                          {featureFlags.appointmentRead &&
                            !featureFlags.customAppointmentWrite && (
                              <li className="dropdown menu-large nav-item">
                                <a
                                  className={`nav-link ${
                                    this.state.appointmentMenu
                                      ? " nav-link-active"
                                      : ""
                                  }`}
                                  data-toggle="dropdown"
                                  href={`/${config.COMPANIES_TOKEN}/appointments`}
                                >
                                  {" "}
                                  Appointments{" "}
                                </a>
                              </li>
                            )}
                        </React.Fragment>
                      )}
                      {this.state.companyAccountClass ===
                        COMPANY_ACCOUNT.INSIGHT && (
                        <li className="nav-item">
                          <a
                            href={config.MEMBERS_URLS.directConnectSettings}
                            className="nav-link"
                          >
                            Direct Connect
                          </a>
                        </li>
                      )}
                      {featureFlags.reviewRead && !pulsem && (
                        <li className="dropdown menu-large nav-item">
                          <a
                            className={`dropdown-toggle nav-link ${
                              this.state.reviewMenu ? " nav-link-active" : ""
                            }`}
                            data-toggle="dropdown"
                            onClick={() => this.showMenu(HEADER_MENU.reviews)}
                          >
                            {" "}
                            Reviews{" "}
                          </a>
                          <ul
                            className={`dropdown-menu megamenu reviews-menu${
                              this.state.reviewMenu ? " show-menu" : ""
                            }`}
                          >
                            <div className="row">
                              {reviewMenuOptions.map((menuOption) => (
                                <li key={menuOption.id} className="col-md-6">
                                  <div className="dropdown-item">
                                    <a
                                      href="#"
                                      onClick={() => {
                                        if (menuOption.id === 1) {
                                          mixpanel.track(
                                            EVENT_VIEWED_REVIEW_RESULTS,
                                            {
                                              from: "menu",
                                            },
                                            null,
                                            () => {
                                              window.location = menuOption.url;
                                            },
                                          );
                                        } else {
                                          window.location = menuOption.url;
                                        }
                                      }}
                                    >
                                      <div className="pull-left">
                                        <img
                                          src={menuOption.icon}
                                          className="menu-icon"
                                        />
                                      </div>
                                      <div className="menu-text">
                                        <div className="menu-header">
                                          {menuOption.label}
                                        </div>
                                        <div className="menu-sub-title">
                                          {menuOption.description}
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </li>
                              ))}
                            </div>
                          </ul>
                        </li>
                      )}

                      {pulsem && (
                        <li className="dropdown menu-large nav-item">
                          <a
                            className={`dropdown-toggle nav-link ${
                              this.state.reviewMenu ? " nav-link-active" : ""
                            }`}
                            data-toggle="dropdown"
                            onClick={() => this.showMenu(HEADER_MENU.reviews)}
                          >
                            {" "}
                            Reviews{" "}
                          </a>
                          <ul
                            className={`dropdown-menu megamenu campaign-menu${
                              this.state.reviewMenu ? " show-menu" : ""
                            }`}
                          >
                            <li className="dropdown-item">
                              <a
                                href="https://dashboard.pulsem.me/login"
                                target="pulseM"
                                onClick={() => {
                                  mixpanel.track(
                                    EVENT_PM_LOGIN,
                                    {
                                      "Triggered from": getLocation(),
                                    },
                                    null,
                                  );
                                }}
                              >
                                <div className="pull-left">
                                  <img
                                    src={getReviewsIcon}
                                    className="menu-icon"
                                  />
                                </div>
                                <div className="menu-text">
                                  <div className="menu-header">Get Reviews</div>
                                  <div className="menu-sub-title">
                                    Access to your pulseM reviews from here
                                  </div>
                                  <div className="mt-2">
                                    <span className="powered">Powered by</span>
                                    <img
                                      src={pulsemIcon}
                                      className="pulsemicon"
                                      alt="pulsem"
                                    />
                                  </div>
                                </div>
                              </a>
                            </li>
                            )
                          </ul>
                        </li>
                      )}
                      {userType === "enterprise" ? (
                        <li className="dropdown menu-large nav-item">
                          <a
                            className={`dropdown-toggle nav-link ${
                              this.state.settingsMenu ? " nav-link-active" : ""
                            }`}
                            data-toggle="dropdown"
                            onClick={() => this.showMenu(HEADER_MENU.settings)}
                          >
                            {" "}
                            Settings{" "}
                          </a>
                          <ul
                            className={`dropdown-menu megamenu setting-menu${
                              this.state.settingsMenu ? " show-menu" : ""
                            }`}
                          >
                            <div className="row">
                              <li className="col-md-6">
                                <div className="dropdown-item">
                                  <a
                                    href={`/${config.COMPANIES_TOKEN}/settings`}
                                  >
                                    <div className="pull-left">
                                      <img
                                        src={companyProfile}
                                        className="menu-icon"
                                      />
                                    </div>
                                    <div className="menu-text">
                                      <div className="menu-header">
                                        Company Settings
                                      </div>
                                      <div className="menu-sub-title">
                                        Manage your company info, business hours
                                        and logo, etc
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                              <ManagerAuthBlock manager={showIsManager}>
                                <li className="col-md-6">
                                  <div className="dropdown-item">
                                    <a
                                      href={`/companies/${this.state.companyId}/${COMPANY_SETTINGS_ROUTES.billing}`}
                                    >
                                      <div className="pull-left">
                                        <img
                                          src={billing}
                                          alt="Billing"
                                          className="menu-icon"
                                        />
                                      </div>

                                      <div className="menu-text">
                                        <div className="menu-header">
                                          Billing
                                        </div>
                                        <div className="menu-sub-title">
                                          Manage your billing settings
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </li>
                              </ManagerAuthBlock>

                              <li className="col-md-6">
                                <div className="dropdown-item">
                                  <a
                                    href={
                                      config.MEMBERS_URLS.directConnectSettings
                                    }
                                  >
                                    <div className="pull-left">
                                      <img
                                        src={directConnect}
                                        alt="Direct Connect"
                                        className="menu-icon"
                                      />
                                    </div>
                                    <div className="menu-text">
                                      <div className="menu-header">
                                        Direct Connect
                                      </div>
                                      <div className="menu-sub-title">
                                        Direct Connect is an application that
                                        connects your Customer Lobby account to
                                        your business’s workflow or accounting
                                        software
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>

                              <li className="col-md-6">
                                <div className="dropdown-item">
                                  <a href={`/${config.COMPANIES_TOKEN}/users`}>
                                    <div className="pull-left">
                                      <img
                                        src={users}
                                        alt="Users"
                                        className="menu-icon"
                                      />
                                    </div>
                                    <div className="menu-text">
                                      <div className="menu-header">Users</div>
                                      <div className="menu-sub-title">
                                        Manage your company’s users
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>

                              <li className="col-md-6">
                                <div className="dropdown-item">
                                  <a
                                    href={`/${config.COMPANIES_TOKEN}/widgets`}
                                  >
                                    <div className="pull-left">
                                      <img
                                        src={widget}
                                        alt="Widget"
                                        className="menu-icon"
                                      />
                                    </div>
                                    <div className="menu-text">
                                      <div className="menu-header">Widget</div>
                                      <div className="menu-sub-title">
                                        Manage widget styles and settings
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            </div>
                          </ul>
                        </li>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {featureFlags.prospectMarketingWrite && (
                    <li className="nav-item">
                      <a
                        href={`/companies/${this.state.companyId}/prospect-marketing/manage`}
                        className="nav-link"
                      >
                        Prospect Marketing
                      </a>
                    </li>
                  )}
                </ul>
                <ul className="nav navbar-nav pull-right user-menu">
                  <PopupInfo
                    placement="bottom"
                    tooltipClassName="help-center-tooltip"
                    icon={
                      <li className="nav-item">
                        {this.state.partnerCompanyId == null ||
                        this.state.partnerCompanyId === "" ||
                        this.state.companyAccountClass === "complete" ? (
                          <a
                            href={`/companies/${this.state.companyId}/help-center`}
                            className="nav-link information-menu"
                          >
                            <img
                              src={information}
                              className="information-icon"
                            />
                          </a>
                        ) : (
                          <div className="nav-link information-menu">
                            <img
                              src={information}
                              alt="Info"
                              className="information-menu information-icon"
                              onClick={() => {
                                mixpanel.track(EVENT_HELP_LEARNING_CENTRE, {
                                  "Triggered from": window.location.href,
                                  "Partner User Id": this.state.partnerUserId,
                                });
                                this.context.setShowLearningCenter(true);
                              }}
                            />
                          </div>
                        )}
                      </li>
                    }
                    message={
                      this.state.partnerCompanyId == null ||
                      this.state.partnerCompanyId === "" ||
                      this.state.companyAccountClass === "complete"
                        ? "Help Center"
                        : "Learning Center"
                    }
                  />
                  <li className="dropdown menu-large nav-item">
                    <a
                      className={`dropdown-toggle nav-link profile-menu${
                        this.state.usersMenu ? " nav-link-active" : ""
                      }`}
                      data-toggle="dropdown"
                      onClick={() => this.showMenu(HEADER_MENU.users)}
                    >
                      <span className="profile-section">
                        <img
                          src={profile}
                          alt="Profile"
                          className="profile-icon"
                        />
                      </span>
                      {this.state.userName}
                    </a>
                    <ul
                      className={`dropdown-menu dropdown-menu-right megamenu${
                        this.state.usersMenu ? " show-menu" : ""
                      }`}
                    >
                      {this.state.ghostUser === "false" ? (
                        <li className="dropdown-item user-menu-dropdown-item">
                          <a
                            href={`/companies/${this.state.companyId}/users/${this.state.userId}/my_profile`}
                          >
                            <div className="pull-left">
                              <img
                                src={user}
                                alt="User"
                                className="menu-icon"
                              />
                            </div>
                            <div className="menu-text">
                              <div className="menu-header menu-header-default-font">
                                My Profile
                              </div>
                            </div>
                          </a>
                        </li>
                      ) : null}
                      <li className="dropdown-item user-menu-dropdown-item">
                        <a
                          href={
                            userType === "enterprise"
                              ? config.MEMBERS_URLS.settings
                              : `/companies/${this.state.companyId}/settings`
                          }
                        >
                          <div className="pull-left">
                            <img
                              src={reviewsSettingsIcon}
                              className="menu-icon"
                            />
                          </div>

                          <div className="menu-text">
                            <div className="menu-header menu-header-default-font">
                              {userType === "enterprise"
                                ? "Company Profile"
                                : "Settings"}
                            </div>
                          </div>
                        </a>
                      </li>

                      <li className="dropdown-item user-menu-dropdown-item">
                        <a id="logout-btn" onClick={this.logout}>
                          <div className="pull-left">
                            <img
                              src={logout}
                              alt="Logout"
                              className="menu-icon"
                            />
                          </div>

                          <div className="menu-text">
                            <div className="menu-header menu-header-default-font header-logout-user-action">
                              Logout
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <HelpAndGetStarted
              context={this.context}
              mixpanel={mixpanel}
              show={showLearningCenter}
              activeTab={activeTabForLearningCenter}
              companyId={this.state.companyId}
              onClose={() => {
                this.context.setShowLearningCenter(false);
                this.context.setActiveTabForLearningCenter(0);
              }}
            />
          </div>
        )}
      </MixpanelConsumer>
    );
  }
}

export default connect()(enhanceWithClickOutside(Header));
