import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Button from "src/shared/styleguide/button/Button";
import Modal from "src/shared/styleguide/modal/Modal.jsx";
import AddCustomersImg from "src/assets/images/common/add-users.svg";
import SyncCustomersImg from "src/assets/images/common/sync-users.svg";
import { MEMBERS_URLS } from "src/shared/Config.js";
import "./AddCustomers.scss";

// lazy load
const UploadContact = React.lazy(() =>
  import("src/components/upload_contact/UploadContact"),
);

const AddCustomers = ({
  directConnectLastRunDate,
  directConnectSettings,
  mixpanel,
  setShowAddCustomersModal,
  showAddCustomersModal,
  totalCustomers,
}) => {
  // state
  const history = useHistory();
  const [
    showUploadCustomersModal,
    setshowUploadCustomersModal,
  ] = React.useState(false);

  // variables
  const showDcConnect = !directConnectLastRunDate && !directConnectSettings;

  // methods
  const switchModal = () => {
    const modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
    // Smooths transition from add to upload modal
    modalBackdrop.style.transition = "unset";
    setShowAddCustomersModal(false);
    setshowUploadCustomersModal(true);
  };

  // effects

  // render
  return (
    <React.Fragment>
      <Suspense fallback={<React.Fragment />}>
        <UploadContact
          mixpanel={mixpanel}
          show={showUploadCustomersModal}
          setShow={setshowUploadCustomersModal}
        />
      </Suspense>
      <Modal
        modalSize={"lg"}
        className="add-customers-modal"
        onHide={() => setShowAddCustomersModal(false)}
        header={<h5 className="modal-title">Add Customers</h5>}
        body={
          <div>
            <React.Fragment>
              <Row className="px-3 mx-1 ">
                <Col sm={totalCustomers > 0 ? 12 : 6} className="center-block">
                  {/* Upload Card  */}
                  <div className="credits-card">
                    <div className="modal-body-header">
                      <img src={AddCustomersImg} alt="Add Customers"/>
                    </div>
                    <div className="info-section">
                      <h2>Add Customers via manual upload</h2>
                      <div className="content-separator">
                        Upload your customers details using a CSV file into
                        Customer Lobby.
                      </div>
                    </div>
                    <div className="cta-section">
                      <Button
                        variant="primary"
                        onClick={() => {
                          switchModal();
                        }}
                      >
                        Upload Customers
                      </Button>
                    </div>
                  </div>
                </Col>
                {totalCustomers === 0 && (
                  <Col sm={6}>
                    {/* Direct Connect Card  */}
                    <div className="credits-card">
                      <div className="modal-body-header">
                        <img src={SyncCustomersImg} alt="Sync Customers" />
                      </div>
                      <div className="info-section">
                        <h2>Sync customers using Direct Connect</h2>
                        <div className="content-separator">
                          Connect with your workflow or accounting application
                          to sync your customer information and activity.
                        </div>
                      </div>
                      <div className="cta-section">
                        {true && (
                          <Button
                            variant="primary"
                            onClick={() => {
                              history.push(MEMBERS_URLS.directConnectSettings);
                            }}
                          >
                            Sync Customers
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </React.Fragment>
          </div>
        }
        show={showAddCustomersModal}
      />
    </React.Fragment>
  );
};

AddCustomers.propTypes = {
  mixpanel: PropTypes.object,
  showAddCustomersModal: PropTypes.bool,
  setShowAddCustomersModal: PropTypes.func,
};

export default AddCustomers;
