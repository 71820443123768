import React from "react";
import BlockUi from "react-block-ui";
import StarRatings from "react-star-ratings";
import NumberUtil from "src/utils/NumberUtil";
import { MiniCard } from "src/shared/styleguide/card/MiniCard";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { EVENT_REVIEW_RESULTS_INTERACTIONS } from "src/constants/MixpanelEvents";

const OverallRating = ({ loading, data, companyId, filter, mixpanel }) => {
  // variables
  const averageScore = data ? data.averageScore * 5 : 0;
  const averageScoreStr =
    Number.isInteger(averageScore) && averageScore === 0
      ? averageScore
      : averageScore.toFixed(1);
  // methods
  const trackEvent = () => {
    if (mixpanel && filter && filter.label) {
      mixpanel.track(EVENT_REVIEW_RESULTS_INTERACTIONS, {
        Action: "Click",
        Destination: "All Reviews",
      });
    }
  };

  // render
  return (
    <MiniCard
      popupInfoMsg="This refers to the average rating of all published Customer Lobby reviews for the selected date range."
      title="OVERALL RATING"
    >
      <BlockUi blocking={loading}>
        <div className="px-3 d-flex align-items-center">
          <span className="h1 text-dark font-weight-bold my-0 mr-2">
            {averageScoreStr}
          </span>
          <StarRatings
            rating={averageScore}
            starRatedColor="#40a40b"
            starEmptyColor="#cccccc"
            starDimension="0.9rem"
            starSpacing="0.125rem"
            changeRating={null}
            numberOfStars={5}
            name="rating"
          />
        </div>
        <div className="px-3 pt-1">
          <Link
            className="review-results-link"
            onClick={trackEvent}
            to={{
              pathname: `/companies/${companyId}/manage-reviews`,
              search: `date=${filter.id}&viewBy=0`,
            }}
            title={`Based on ${data ? data.allReviews : 0} reviews`}
          >
            {`Based on ${NumberUtil.toK(data ? data.allReviews : 0)} reviews`}
          </Link>
        </div>
      </BlockUi>
    </MiniCard>
  );
};

OverallRating.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
  companyId: PropTypes.string.isRequired,
};

export default OverallRating;
