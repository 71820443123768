import React from "react";
import PropTypes from "prop-types";
import Dropdown, {
  DropdownButton,
} from "src/shared/styleguide/dropdown/Dropdown";
import { showCompanyId } from "src/shared/SessionHelper.js";
import "./CustomerSegmentsContainer.scss";
import { UPLOAD_OF_CUSTOMERS_NEW_MEMBERS } from "src/constants/MixpanelEvents";
import newCustomerImage from "src/assets/images/customer/create_customer.svg";
import newSegmentImage from "src/assets/images/segments/create_segment.svg";
import { useHistory } from "react-router-dom";

const CreateNewButton = ({ setShowAddCustomersModal, mixpanel }) => {
  const history = useHistory();
  return (
    <div className="new-customer-btn pull-right">
      <DropdownButton title="+ New ">
        <Dropdown.Item
          onClick={(e) => {
            e.stopPropagation();
            setShowAddCustomersModal(true);
            mixpanel.track(UPLOAD_OF_CUSTOMERS_NEW_MEMBERS, {
              "Action: The member wanting to upload customers": {
                location: "customers",
              },
            });
          }}
        >
          <img src={newCustomerImage} alt="Add Customers" /> Add Customers
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/companies/${showCompanyId}/segments/new`, {
              prevPage: document.location.pathname,
            });
          }}
        >
          <img src={newSegmentImage} alt="Create Segment" /> Create Segment
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

CreateNewButton.propTypes = {
  setShowAddCustomersModal: PropTypes.func,
};

export default CreateNewButton;
