import React from "react";
import BlockUi from "react-block-ui";
import { MiniCard } from "src/shared/styleguide/card/MiniCard";
import {
  MINI_CARD_HEIGHT,
  MINI_CARD_CONTENT_HEIGHT,
} from "src/constants/ReviewResults";
import MiniDonutChart from "./MiniDonutChart";
import PropTypes from "prop-types";
import { EVENT_REVIEW_RESULTS_INTERACTIONS } from "src/constants/MixpanelEvents";

const PublishedReviews = ({
  loading,
  fromDate,
  toDate,
  data,
  companyId,
  filter,
  mixpanel,
}) => {
  return (
    <MiniCard
      popupInfoMsg="This refers to the total number of reviews published in the selected date range. It also shows the distribution of negative vs. positive among the reviews published."
      title="PUBLISHED REVIEWS"
      viewMore={{
        to: {
          pathname: `/companies/${companyId}/manage-reviews`,
          search: `date=${filter.id}&viewBy=0`,
        },
        label: "View more",
        onClickViewMore: () => {
          if (mixpanel) {
            mixpanel.track(EVENT_REVIEW_RESULTS_INTERACTIONS, {
              Action: "Click",
              Destination: "Published Reviews",
            });
          }
        },
      }}
    >
      <BlockUi blocking={loading}>
        <div className="px-3">
          <MiniDonutChart
            chartId="publishedReviewsChart"
            height={MINI_CARD_HEIGHT}
            pieSize={MINI_CARD_CONTENT_HEIGHT}
            chartTitle={data ? data.published : 0}
            chartData={[
              {
                y: data ? data.positive : 0,
                name: "Positive",
                color: "#5cb85c",
              },
              {
                y: data ? data.negative : 0,
                name: "Negative",
                color: "#cacaca",
              },
            ]}
            tooltipFormatter={function() {
              const { data } = this.series;
              const total = data[0].y + data[1].y || 1;
              return `${fromDate ? fromDate : ""}${
                toDate && toDate !== fromDate ? ` to ${toDate}` : ""
              }
                <br />${data[0].name}: ${Math.ceil(data[0].y)} (${Math.ceil(
                (data[0].y * 100) / total,
              )}%)
                <br />${data[1].name}: ${Math.floor(data[1].y)} (${Math.floor(
                (data[1].y * 100) / total,
              )}%)`;
            }}
          />
        </div>
      </BlockUi>
    </MiniCard>
  );
};

PublishedReviews.propTypes = {
  loading: PropTypes.bool.isRequired,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  data: PropTypes.object,
  companyId: PropTypes.string.isRequired,
  filter: PropTypes.object,
};

export default PublishedReviews;
