const EVENT_VIEWED_REVIEW_RESULTS = "Member Area v2: Review Results";
const EVENT_REVIEW_RESULTS_FILTER = "Member Area v2: Reviews Results Filter";
const EVENT_REVIEW_RESULTS_INTERACTIONS =
  "Member Area v2: Review Results Interactions";
const EVENT_VIEWED_MANAGE_REVIEWS = "Member Area v2: Manage Reviews";
const EVENT_MANAGE_REVIEWS_FILTER = "Member Area v2: Manage Reviews Filter";
const EVENT_VIEWED_TRACK_INVITATIONS = "Member Area v2: Track Invitations";
const EVENT_VIEWED_PRIVATE_FEEDBACK = "Member Area v2: Private Feedback";
const EVENT_PRIVATE_FEEDBACK_FILTER = "Member Area v2: Private Feedback Filter";
const EVENT_TRACK_FILTER = "Member Area v2: Reviews Track Filter";
const EVENT_REVIEW_TABS = "Member Area v2: Review Settings";
const EVENT_REVIEW_SETTINGS = "Member Area v2: Review Settings Invites";
const EVENT_TRACK_INVITE_DESTINATIONS =
  "Member Area v2: Review Settings Invites Destination";
const EVENT_TRACK_SYNDICATION_STATUS = "Member Area v2: Settings Syndication";
// Messaging
const EVENT_OPEN_MESSAGING = "Member Area v2: Open Messaging";
const EVENT_NEW_MESSAGE = "Member Area v2: New Message";
const EVENT_VIEW_UNREAD_MESSAGE = "Member Area v2: View Unread Message";
const EVENT_MEMBER_REPLY = "Member Area v2: Member Reply";
const EVENT_ADD_OR_REMOVE_SEGMENT = "Member Area v2: Add/Remove segment";
const EVENT_STARRED_A_MESSAGE = "Member Area v2: Starred a Message";
const EVENT_MESSAGE_TEMPLATES = "Member Area v2: Message Templates";
const EVENT_QUICK_REPLY = "Member Area v2: Quick Reply";
const EVENT_GET_STARTED = "Member Area v2: Get Started";
const EVENT_VIEW_INSIGHTS = "Member Area v2: View Insights";
const EVENT_GET_STARTED_LEARN_MORE = "Member Area v2: Get Started - Learn more";
const EVENT_VIEW_INSIGHTS_LEARN_MORE =
  "Member Area v2: View Insights - Learn more";
const EVENT_LEARNING_CENTRE = "Member Area v2: Learning Centre";
const EVENT_HELP_LEARNING_CENTRE = "Member Area v2: Help - Learning Centre";
const EVENT_LC_ADD_COMPANY_USER =
  "Member Area v2: Learning Centre - Add Company User";
const EVENT_LC_ADD_COMPANY_PROFILE =
  "Member Area v2: Learning Centre - Add Company Profile";
const EVENT_LC_SETUP_FOLLOW_UP =
  "Member Area v2: Learning Centre - Set Follow Up settings";
const EVENT_LC_SETUP_REVIEWS =
  "Member Area v2: Learning Centre - Set Review settings";
const EVENT_LC_SETUP_APPOINTMENT =
  "Member Area v2: Learning Centre - Set Appointment settings";
// Campaigns
const EVENT_CREATE_CAMPAIGN = "Member Area v2: Create Campaign";
const EVENT_ADD_KEYWORDS_TO_SUBJECT_LINE =
  "Member Area v2: Add keywords to Subject line";
const EVENT_EMAIL_PREVIEW = "Member Area v2: Email Preview";
const EVENT_TEXT_PREVIEW = "Member Area v2: Email Preview";
const EVENT_ADD_EMAIL_ATTACHMENT = "Member Area v2: Email Add Attachment";
const EVENT_LEARN_MORE_INFO = "Member Area v2: View Learn More Info";
const EVENT_CAMPAIGN_DETAILS_EDIT = "Member Area v2: Campaign Details Edit";
const EVENT_PREVIEW_CAMPAIGN_ASSET = "Member Area v2: Preview Campaign Asset";
const EVENT_COMMUNICATION_SUMMARY_DOWNLOAD =
  "Member Area v2: Communications Summary Download";
const EVENT_VIEW_CAMPAIGN_RESULTS = "Member Area v2: Campaigns Result";
const EVENT_VIEW_CAMPAIGN_MANAGE = "Member Area v2: Campaigns Manage";
const EVENT_EMAIL_CREDITS = "Member Area v2: Click to Request Email Credits";
const EVENT_POSTCARD_CREDITS =
  "Member Area v2: Click to Purchase Postcard Credits";
const EVENT_TEXT_CREDITS = "Member Area v2: Click to Request Text Credits";
const EVENT_SELECT_CAMPAIGN_STEP = "Member Area v2: Select Campaign Step";
const EVENT_CREATE_EMAIL_CAMPAIGN_CLICK =
  "Member Area v2: Email Campaign Create Click";
const EVENT_CREATE_POSTCARD_CAMPAIGN_CLICK =
  "Member Area v2: Postcard Campaign Create Click";
const EVENT_CREATE_TEXT_CAMPAIGN_CLICK =
  "Member Area v2: Text Campaign Create Click";
const EVENT_TARGET_ALL_CUSTOMERS = "Member Area v2: TargeT All Customer Click";
const EVENT_BLAST_FREQUENCY = "Member Area v2: Blast Frequency";
const EVENT_OVER_TIME_FREQUENCY = "Member Area v2: Over time Frequency";
// Email Campaigns Create/Edit
const EVENT_UNAPPROVED_CAMPAIGN = "Member Area v2: Unapproved Campaign";
const EVENT_SELECT_EMAIL_TEMPLATE = "Member Area v2: Select Template";
const EVENT_SELECT_SEGMENT = "Member Area v2: Select Segment";
const EVENT_SELECT_CAMPAIGN_TYPE = "Member Area v2: Select Campaign Type";
// Text Campaigns Create/Edit
const EVENT_SELECT_TEXT_TEMPLATE = "Member Area v2: Select Text Template";
const EVENT_ADD_KEYWORDS_TO_TEXT_CAMPAIGN_MESSAGE =
  "Member Area v2: Add keywords to text campaign message";
// Customers
const EVENT_CONTACT_CSV_UPLOAD = "Member Area v2: Contact CSV Upload";
const EVENT_COMMUNICATION_PREFERENCES_EDIT =
  "Member Area v2: Communication Preferences Edit";
const EVENT_CUSTOMER_INFORMATION_EDIT =
  "Member Area v2: Customer Information Edit";
const UPLOAD_OF_CUSTOMERS_NEW_MEMBERS = "Upload of Customers";
const EVENT_SEARCH_CUSTOMER_BY_CITY = "Member Area v2: Search Customer by City";
const EVENT_SEARCH_CUSTOMER_BY_STATE =
  "Member Area v2: Search Customer by State";
const EVENT_SHOW_CUSTOMERS = "Member Area v2: Show Customers";
const ELLIPSE_QUICK_VIEW = "Member Area v2: Ellipse-Quick View";
const ELLIPSE_EDIT = "Member Area v2: Ellipse-Quick Edit";

//Customers_Segments
const EVENT_VIEW_CUSTOMERS = "Member Area v2: Customers";
const EVENT_VIEW_SEGMENTS = "Member Area v2: Segments";

// App Integration
const EVENT_PM_CONNECTION = "Member Area v2: pulseM connection successful";
const EVENT_PM_DISCONNECTION = "Member Area v2: pulseM disconnection";
const EVENT_PM_LOGIN = "Member Area v2: Reviews-> Get Reviews (pulseM login)";

// Segments
const EVENT_MARKED_SEGMENT_FAVORITE = "Member Area v2: Marked Segment Favorite";
const EVENT_MARKED_SEGMENT_ARCHIVE = "Member Area v2: Marked Segment Archive";
const EVENT_CHECK_RECENT_ACTIVITY = "Member Area v2: Check Recent Activity";
const EVENT_VIEW_INDIVIDUAL_SEGMENT = "Member Area v2: View Individual Segment";
const EVENT_LEARN_MORE =
  "Member Area v2: Using Learn More for Creation of Segment";
const EVENT_SEGMENT_CREATION_CANCELLED =
  "Member Area v2: Segment creation cancelled";
const EVENT_SEGMENT_INCLUDE_CRITERIA =
  "Member Area v2: Segments using Customer Details as include criteria";
const EVENT_SEGMENT_EXCLUDE_CRITERIA =
  "Member Area v2: Segments using Customer Details as exclude criteria";
const EVENT_SEGMENT_INCLUDE_AND_ADVANCED_CRITERIA =
  "Member Area v2: Segment combining advanced filter with include criteria";

//Postcard
const EVENT_POSTCARD_PULSEM_REVIEWS =
  "Member Area v2: pulseM Reviews in Postcard campaign";
const EVENT_POSTCARD_CUSTOMERLOBBY_REVIEWS =
  "Member Area v2: Customer Lobby Reviews in Postcard campaign";
const EVENT_POSTCARD_PULSEM_LOGIN = "Member Area v2: Click to login to pulseM";
const EVENT_POSTCARD_FRONT_UPDATE_MESSAGE =
  "Member Area v2: Updated Message of Standard Image - Postcard Front  Template Id:";
const EVENT_POSTCARD_FRONT_USED_IMAGES =
  "Member Area v2: Used Images Folder - Postcard Front ";
const EVENT_POSTCARD_FRONT_CUSTOM_IMAGE =
  "Member Area v2: Upload Custom Image - Postcard Front";
const EVENT_POSTCARD_FRONT_ADD_LOGO_ENABLE =
  "Member Area v2: Enable add logo - Postcard Front";
const EVENT_POSTCARD_FRONT_ADD_LOGO_DISABLE =
  "Member Area v2: Disable add logo - Postcard Front";
const EVENT_POSTCARD_BACK_CUSTOM_IMAGE =
  "Member Area v2: Upload Custom Image - Postcard Back";
const EVENT_POSTCARD_INCLUDE_FIRST_NAME = "Member Area v2: Disable first name";
const EVENT_PAUSE_CAMPAIGN = "Member Area v2: Pause Campaign";
const EVENT_UNPAUSE_CAMPAIGN = "Member Area v2: Unpause Campaign";

//Dashboard
const EVENT_DASHBOARD_SETTINGS = "Member Area v2: Dashboard Settings";
const EVENT_DASHBOARD_RECOMMENDATION =
  "Member Area v2: Recommendation - See My Results";
const EVENT_DASHBOARD_BENCHMARK = "Member Area v2: Dashboard Benchmark";
const EVENT_CAMPAIGN_PERFORMANCE = "Member Area v2: Campaign Performance";
const EVENT_REVIEW_PERFOMANCE = "Member Area v2: Review Performance";
const EVENT_PROSPECT_MARKTING_QUICKLINK = "Member Area v2:Prospect Marketing QuickLink";

//prospect markting
const ENHANCE_YOUR_REACH = "Member Area v2:Enhance Your Reach in Familiar Neighborhoods button";
const EXPAND_INTO_NEW_MARKETS="Member Area v2:Expand Into New Markets";
const UTILIZE_YOUR_OWN_CONTACT_LIST="Member Area v2:Utilize Your Own Contact List";


export {
  EVENT_STARRED_A_MESSAGE,
  EVENT_MESSAGE_TEMPLATES,
  EVENT_QUICK_REPLY,
  EVENT_ADD_OR_REMOVE_SEGMENT,
  EVENT_MEMBER_REPLY,
  EVENT_NEW_MESSAGE,
  EVENT_VIEW_UNREAD_MESSAGE,
  EVENT_OPEN_MESSAGING,
  EVENT_VIEWED_REVIEW_RESULTS,
  EVENT_EMAIL_CREDITS,
  EVENT_POSTCARD_CREDITS,
  EVENT_TEXT_CREDITS,
  EVENT_TRACK_SYNDICATION_STATUS,
  EVENT_REVIEW_RESULTS_FILTER,
  EVENT_REVIEW_RESULTS_INTERACTIONS,
  EVENT_VIEWED_MANAGE_REVIEWS,
  EVENT_MANAGE_REVIEWS_FILTER,
  EVENT_VIEWED_TRACK_INVITATIONS,
  EVENT_VIEWED_PRIVATE_FEEDBACK,
  EVENT_PRIVATE_FEEDBACK_FILTER,
  EVENT_TRACK_FILTER,
  EVENT_REVIEW_TABS,
  EVENT_REVIEW_SETTINGS,
  EVENT_TRACK_INVITE_DESTINATIONS,
  EVENT_GET_STARTED,
  EVENT_VIEW_INSIGHTS,
  EVENT_VIEW_INSIGHTS_LEARN_MORE,
  EVENT_GET_STARTED_LEARN_MORE,
  EVENT_LEARNING_CENTRE,
  EVENT_HELP_LEARNING_CENTRE,
  EVENT_LC_ADD_COMPANY_USER,
  EVENT_LC_ADD_COMPANY_PROFILE,
  EVENT_LC_SETUP_FOLLOW_UP,
  EVENT_LC_SETUP_REVIEWS,
  EVENT_LC_SETUP_APPOINTMENT,
  EVENT_CREATE_CAMPAIGN,
  EVENT_UNAPPROVED_CAMPAIGN,
  EVENT_SELECT_EMAIL_TEMPLATE,
  EVENT_SELECT_SEGMENT,
  EVENT_TARGET_ALL_CUSTOMERS,
  EVENT_BLAST_FREQUENCY,
  EVENT_OVER_TIME_FREQUENCY,
  EVENT_ADD_KEYWORDS_TO_SUBJECT_LINE,
  EVENT_EMAIL_PREVIEW,
  EVENT_TEXT_PREVIEW,
  EVENT_ADD_EMAIL_ATTACHMENT,
  EVENT_LEARN_MORE_INFO,
  EVENT_CAMPAIGN_DETAILS_EDIT,
  EVENT_PREVIEW_CAMPAIGN_ASSET,
  EVENT_COMMUNICATION_SUMMARY_DOWNLOAD,
  EVENT_VIEW_CAMPAIGN_RESULTS,
  EVENT_VIEW_CAMPAIGN_MANAGE,
  EVENT_PM_CONNECTION,
  EVENT_PM_DISCONNECTION,
  EVENT_SELECT_CAMPAIGN_TYPE,
  EVENT_CONTACT_CSV_UPLOAD,
  EVENT_COMMUNICATION_PREFERENCES_EDIT,
  EVENT_CUSTOMER_INFORMATION_EDIT,
  EVENT_VIEW_CUSTOMERS,
  EVENT_VIEW_SEGMENTS,
  EVENT_PM_LOGIN,
  EVENT_MARKED_SEGMENT_FAVORITE,
  EVENT_MARKED_SEGMENT_ARCHIVE,
  EVENT_CHECK_RECENT_ACTIVITY,
  EVENT_VIEW_INDIVIDUAL_SEGMENT,
  EVENT_LEARN_MORE,
  EVENT_SEGMENT_CREATION_CANCELLED,
  EVENT_SEGMENT_INCLUDE_CRITERIA,
  EVENT_SEGMENT_EXCLUDE_CRITERIA,
  EVENT_SEGMENT_INCLUDE_AND_ADVANCED_CRITERIA,
  EVENT_SEARCH_CUSTOMER_BY_CITY,
  EVENT_SEARCH_CUSTOMER_BY_STATE,
  EVENT_SHOW_CUSTOMERS,
  ELLIPSE_QUICK_VIEW,
  ELLIPSE_EDIT,
  UPLOAD_OF_CUSTOMERS_NEW_MEMBERS,
  EVENT_POSTCARD_PULSEM_REVIEWS,
  EVENT_POSTCARD_CUSTOMERLOBBY_REVIEWS,
  EVENT_POSTCARD_PULSEM_LOGIN,
  EVENT_POSTCARD_FRONT_UPDATE_MESSAGE,
  EVENT_POSTCARD_FRONT_USED_IMAGES,
  EVENT_POSTCARD_FRONT_CUSTOM_IMAGE,
  EVENT_POSTCARD_FRONT_ADD_LOGO_ENABLE,
  EVENT_POSTCARD_FRONT_ADD_LOGO_DISABLE,
  EVENT_POSTCARD_BACK_CUSTOM_IMAGE,
  EVENT_POSTCARD_INCLUDE_FIRST_NAME,
  EVENT_PAUSE_CAMPAIGN,
  EVENT_UNPAUSE_CAMPAIGN,
  EVENT_SELECT_CAMPAIGN_STEP,
  EVENT_CREATE_EMAIL_CAMPAIGN_CLICK,
  EVENT_CREATE_POSTCARD_CAMPAIGN_CLICK,
  EVENT_CREATE_TEXT_CAMPAIGN_CLICK,
  EVENT_SELECT_TEXT_TEMPLATE,
  EVENT_ADD_KEYWORDS_TO_TEXT_CAMPAIGN_MESSAGE,
  EVENT_DASHBOARD_SETTINGS,
  EVENT_DASHBOARD_RECOMMENDATION,
  EVENT_DASHBOARD_BENCHMARK,
  EVENT_CAMPAIGN_PERFORMANCE,
  EVENT_REVIEW_PERFOMANCE,
  EVENT_PROSPECT_MARKTING_QUICKLINK,
  ENHANCE_YOUR_REACH,
  EXPAND_INTO_NEW_MARKETS,
  UTILIZE_YOUR_OWN_CONTACT_LIST,
};
