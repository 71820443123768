import React from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SegmentContent from "./SegmentContent";
import useDocumentTitle from "src/utils/hooks/useDocumentTitle";
import { PageHeader } from "src/shared/styleguide/common/PageHeader";
import { SEGMENT_TAB } from "src/shared/Constants";
import "./Segment.scss";

const Segment = ({ mixpanel }) => {
  useDocumentTitle(SEGMENT_TAB.documentTitle);

  const history = useHistory();
  const isNew = history.location.pathname.includes("/segments/new");
  const prevPage =
    history.location.state &&
    history.location.state.prevPage
      ? history.location.state.prevPage
      : "";
  const headerRef = React.useRef({ prevPage: prevPage });
  const pageHeader = `${isNew ? "Create" : "Edit"} Segment`;
  const usePrevPage =
    headerRef && headerRef.current && !!headerRef.current.prevPage;

  // render
  return (
    <React.Fragment>
      <PageHeader
        className="segment-header"
        showArrow={usePrevPage}
        onArrowClick={() => {
          history.push(usePrevPage ? headerRef.current.prevPage : "#", {
            prevPage: document.location.pathname,
          });
        }}
        leftComponent={pageHeader}
      />
      <div className="segment-container">
        <div className="segment-block">
          <Row>
            <Col sm={12}>
              <SegmentContent mixpanel={mixpanel} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Segment;
